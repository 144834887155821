import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Card, Col, Dropdown, Form, Image, Input, Pagination, Popconfirm, Row, Space, Spin, Table, Tooltip, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { fechaATextoSimple, fechaATextoSimpleExperimental, fechaUTCATexto } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import EditarNuevoCampoPersonalizado from "./editar"
import { DownOutlined, FileExcelFilled, SearchOutlined } from "@ant-design/icons"
import ButtonGroup from "antd/es/button/button-group"
import { Esquema } from "../../lib/esquemas/tablas"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import { IoMdAlert, IoMdRemoveCircle, IoMdTime } from 'react-icons/io'
import { TbHelpCircleFilled } from "react-icons/tb"
import { modificarInterfaz } from "../../redux/actions/interfaz"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import { main_data } from "./data"
import { FaCheckCircle } from "react-icons/fa"
import { MdOutlinePendingActions } from "react-icons/md"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import TagSinDatos from "../../subComponents/general/tag_sin_datos"

const ListadoEntradas = (props)=> {
    const {
        condicion_default,
        formulario,
        hideSearch
    } = props
    const dispatch = useDispatch()
    const sesion                    = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [deleting, setDeleting]   = useState(false)
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ]     = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ]       = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ loadingUpdate, setLoadingUpdate ] = useState(false)
    const pais                      = useSelector(state => state.pais)
    const session                   = useSelector(state => state.miusuario)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const idioma                    = useSelector(state => state.idioma)
    const trash                     = props.trash ? props.trash : false
    const tipo                      = props.tipo ? props.tipo : "link"
    const titulo                    = props.title ? props.title : 'registros'
    const key_interfaz              = main_data.key_interfaz
    const key_esquema               = main_data.key_esquema
    const slug_module               = main_data.slug_module
    const exclude_fields            = [ 'texto','title','divisor' ]

    
    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/category`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const modificarStatus = async (status, id)=>{
        if(loadingUpdate) return 
        setLoadingUpdate(true)
        return fetch(`${urlapi}/${slug_module}/entradas/status`,{
            method:'POST',
            body: JSON.stringify({
                status, id
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                setConductores(prev => {
                    let actual = [...prev]
                    const i = actual.findIndex(e => e._id === id)
                    if( i > -1 ) {
                        actual[i].status = status
                        if(res.actualizado_por_ultima_vez) actual[i].actualizado_por_ultima_vez = res.actualizado_por_ultima_vez
                    }
                    return [ ...actual ]
                })
            }
            return setLoadingUpdate(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingUpdate(false)
        })
    }
    const obtenerRegistros = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${slug_module}/entradas/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    const eliminarRecurso = async (id) => {
        setDeleting(true)
        return fetch(`${urlapi}/${slug_module}/entradas?id=${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(res._id){
              messageApi.success("Eliminado exitosamente")
              obtenerRegistros(1, false)
            }
            return setDeleting(false)
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setDeleting(false)
          })
      }
  

    useEffect(() => {
        obtenerRegistros(1, condicion_default)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerRegistros(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination showSizeChanger={false} defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const activarDesactivarTabla = (value, column) => {
        const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario) )
        const i = instancia_esquema[key_interfaz].findIndex(e => e.key === column.llave)
        if(i > -1){
            instancia_esquema[key_interfaz][i].active = value
        }
        console.log({ instancia_esquema, i, column })
        return dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
    }

    const EncabezadoPesonalizado = (model) => {

        const { label, helptext } = model

        const object_delete = <Tooltip title={"No mostrar columna"}><IoMdRemoveCircle style={{ verticalAlign: "middle" }} size={20} className="hover" onClick={() => activarDesactivarTabla(false, model)} /></Tooltip>

        if(helptext) {
            return <div>
            <Tooltip title={helptext}>
            <Paragraph className="mb-0 mt-0">{label} <TbHelpCircleFilled /></Paragraph>
            </Tooltip>
            {object_delete}            
            </div>
        }

        return <>
            <Paragraph className="mb-0 mt-0">{object_delete} {label}</Paragraph>
            
        </>
    }
    
    const obtenerDetallesEsquema = (slug) => {
        const i = Esquema[ key_esquema ].findIndex(e => e.key === slug)
        if( i > -1){
            return {
                ...Esquema[ key_esquema ][i],
                llave: Esquema[ key_esquema ][i].key
            }
        }
        return {}
    }

    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />

        const component_title = <Column title="Email creador" render={(data) => {
            return <div>{data.email ? data.email : <TagSinDatos /> }</div>
        }} />

        const component_acciones = <Column title="ACCIONES" render={(data) => {

            let items = [
                {
                    label: (
                      <div onClick={() => modificarStatus("approved", data?._id)}>APROBAR</div>
                    ),
                },
                {
                    label: (
                      <div onClick={() => modificarStatus("reviewing", data?._id)}>EN REVISIÓN</div>
                    ),
                },
                {
                    label: (
                      <div onClick={() => modificarStatus("denied", data?._id)}>NO APROBADA</div>
                    ),
                },
            ].map((e,pos) => ({ ...e, key: `tec-${pos}` }))

            return <div>
                <Dropdown
                    menu={{
                    items
                    }}
                    disabled={loadingUpdate}
                >
                    <a onClick={(e) => e.preventDefault()}>
                    <Space>{ loadingUpdate ? <Spin size="small" /> : false } OPCIONES<DownOutlined/></Space>
                    </a>
                </Dropdown>
            </div>
        }} />

        const component_status = <Column title={"Status"} render={(data) => {
            const estados = {
                denied: {
                    title: "Denegado",
                    icon: <IoMdAlert size={20} style={{ verticalAlign: "middle" }} />
                },
                approved: {
                    title: "Aceptado",
                    icon: <FaCheckCircle size={20} style={{ verticalAlign: "middle" }} />
                },
                reviewing: {
                    title: "En revisión",
                    icon: <IoMdTime size={20} style={{ verticalAlign: "middle" }} />
                },
                send: {
                    title: "Pendiente de revisión",
                    icon: <MdOutlinePendingActions size={20} style={{ verticalAlign: "middle" }} />
                },
            }
            return <div style={{ textTransform: "uppercase" }}>{estados[data.status]?.icon} {estados[data.status] ? estados[data.status]?.title : data.status}</div>
        }} />

        const component_actualizado_por = <Column title="Última revisión por" render={(data) => {
            return <div>{data?.actualizado_por_ultima_vez?.title ? <div>
                {data?.actualizado_por_ultima_vez?.title}<br/><b style={{ fontSize: 11 }}>{fechaUTCATexto(data?.actualizado_por_ultima_vez?.date)}</b>
            </div> : <TagSinDatos />}</div>
        }} />
        const component_creador = <Column title="Vehículo" render={(data) => {
            return <div>{data?.vehiculo?.detalle_modelo	} {data?.vehiculo?.patente}</div>
        }} />
        const component_conductor = <Column title="Conductor" render={(data) => {
            return <div>{data?.vehiculo?.detalles_conductor?.nombres} {data?.vehiculo?.detalles_conductor?.apellido_p	}</div>
        }} />
        const component_fecha = <Column title="Creación" render={(data) => {
            return <div>{fechaUTCATexto(data.createdAt)}</div>
        }} />

        return <div>
            {paginacion(data.pagina, total)}
            <Table dataSource={conductores} size="small" pagination={false} scroll={estilo_moving_truck.scroll_row} >
                {component_creador}
                {component_conductor}
                {component_fecha}
                {/* { encontrarLlaveValorEnEsquema("title", interfaz_usuario[ key_interfaz ]) === false ? false : component_title } */}
                {
                    formulario.filter(e => !exclude_fields.includes(e.slug)).map((column, index) => {
                        return <Column title={column.label} key={index} render={(data) => {
                            let valor = ''
                            let image = ''
                            const i = data.entradas.findIndex(e => e.label.toString().toLowerCase() === column.label.toString().toLowerCase())
                            if(i > -1 ) {
                                if( data.entradas[i]?.image?.url ) image = data.entradas[i].image.url
                                if( data.entradas[i]?.slug === "checkbox" ){
                                    valor = data.entradas[i].checked ? "SI" : "NO"
                                } else {
                                    valor = data.entradas[i].value
                                }
                            }
                            return <div>{valor ? valor : <TagSinDatos /> }
                            { image ? <Image src={image} alt={image} style={{ maxWidth: 40, maxHeight: 40, padding: 5 }} /> : false }
                            </div>
                        } } />
                    })
                }
                {component_status}
                {component_actualizado_por}
                {/* { encontrarLlaveValorEnEsquema("createdAt", interfaz_usuario[ key_interfaz ]) === false ? false : component_fecha } */}
                {component_acciones}
                
                <Column title="Eliminar" render={(data) => {
                    return <div>
                        <Popconfirm
                    title="Eliminar"
                    description="¿Estás seguro que deseas eliminar recurso? Esta acción no se puede deshacer"
                    onConfirm={() => eliminarRecurso(data._id)}
                    onCancel={() => false}
                    okText="SI"
                    style={{ display: "inline-block"}}
                    cancelText="NO"
                  >
                  <Button loading={deleting} size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
                  </div>
                }} />
            </Table>
        </div>
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setCondicionBusqueda(condicion_default);
            setPagina(1);
            return obtenerRegistros(1, condicion_default);
        }
        const condicion = {
            ...condicion_default,
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerRegistros(1, condicion);
      };

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

    const buscador = () => {
        if(hideSearch === true) return false
        return <Col span="auto"><Form onSubmitCapture={handleSubmit}>
                    <Row gutter={15}>
                        <Col md={14}>
                            <Form.Item label="Buscar">
                                <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Buscar por palabras clave`} />
                            </Form.Item>
                        </Col>
                        <Col md={10}>
                            <Form.Item>
                                <Button onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
        </Col>
      }

    return <div>
        {contextHolder}
        <Row gutter={15}>
            {buscador()}
            {/* <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
            <Col span="auto"><Button disabled className="mb-3" ><FileExcelFilled /> DESCARGAR PDF INFORMATIVO</Button></Col> */}
            <Col span="auto"><Button disabled loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green"  onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col>
        </Row>
        
        {mostrarRegistros()}
    </div>
}
export default ListadoEntradas