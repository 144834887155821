import { Button, Card, Col, Input, Row, Upload, Cascader, DatePicker, Form, InputNumber, Mentions, Select, TreeSelect, Divider, Layout, Checkbox, Modal, Tooltip, Tag, Switch, Drawer, Space, message, List, Tabs, } from "antd";
import React, { useEffect, useState } from "react";
import Structure from "../Structure";
import Title from "antd/es/typography/Title";
import { TbSelect } from "react-icons/tb";
import { FaCheckCircle, FaCloudUploadAlt, FaHeading, FaRegTrashAlt } from "react-icons/fa";
import { BsBoxArrowInUpRight, BsCalendarDate, BsInputCursorText } from "react-icons/bs";
import { RxDividerHorizontal } from "react-icons/rx";
import { RiCheckboxLine } from "react-icons/ri";
import { MdOutlineDateRange, MdOutlineEmail } from "react-icons/md";
import { BiText } from "react-icons/bi";
import Paragraph from "antd/es/typography/Paragraph";
import ButtonGroup from "antd/es/button/button-group";
import { urlapi } from "../../lib/backend/data";
import { main_data } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import ModalSeleccionVehiculos from "../Conductores/modal_seleccion";
import { DeleteOutlined } from "@ant-design/icons";
import { validateEmail } from "../../lib/helpers/helpers";
import { IoMdAlert } from "react-icons/io";
import CargandoTabla from "./cargando";
import ListadoNotificaciones from "../Notificaciones/listado";
import ModalSeleccionVehiculo from "../Vehiculos/modal_seleccion";

const EditarTarea = (props) => {
    const {
        id_formulario,
        id_tarea
    } = props
    const sizeIcon = 25

    const elementos = [
        {
            id: "",
            slug: "input",
            title: "Campo de texto",
            icon: <BsInputCursorText size={sizeIcon}  />
        },
        {
            id: "",
            slug: "email",
            title: "Email",
            icon: <MdOutlineEmail size={sizeIcon}  />
        },
        {
            id: "",
            slug: "texto",
            title: "Texto",
            icon: <BiText size={sizeIcon}  />
        },
        {
            id: "",
            slug: "title",
            title: "Título",
            icon: <FaHeading size={sizeIcon}  />
        },
        {
            id: "",
            slug: "archivo",
            title: "Carga",
            icon: <FaCloudUploadAlt size={sizeIcon}  />
        },
        {
            id: "",
            slug: "divisor",
            title: "Divisor",
            icon: <RxDividerHorizontal size={sizeIcon}  />
        },
        {
            id: "",
            slug: "select",
            title: "Selector",
            icon: <TbSelect size={sizeIcon}  />
        },
        {
            id: "",
            slug: "checkbox",
            title: "Checkbox",
            icon: <RiCheckboxLine  size={sizeIcon} />
        },
        {
            id: "",
            slug: "datepicker",
            title: "Datepicker",
            icon: <MdOutlineDateRange size={sizeIcon}  />
        },
        {
            id: "",
            slug: "rangepicker",
            title: "Range Picker",
            icon: <BsCalendarDate size={sizeIcon}  />
        },
    ]
    const [ campos, setCampos ] = useState([])
    const [ indexSeleccionado, setIndexSeleccionado ] = useState(-1)
    const [open, setOpen] = useState(false)
    const [ loading, setLoading ] = useState()
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ registro, setRegistro ] = useState({})
    const [ usuarios, setUsuarios ] = useState([])
    const [messageApi, contextHolder] = message.useMessage()
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)

    const consultarRegistro = async ()=>{
        if(!id_tarea) return
        setLoadingMaster(true)
        return fetch(`${urlapi}/${main_data.slug_module}/tareas?id=${id_tarea}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                setRegistro(res)
                if(res.usuarios) if(Array.isArray(res.usuarios)) setUsuarios(res.usuarios)
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    const crearRegistro = async ()=>{
        const instancia = { ...registro }

        let requeridos = [
            { value: "descripcion", label: "Descripción" },
            // { value: "vencimiento", label: "Fecha de vencimiento" },
        ]
        let faltantes = []
        requeridos.map(field => {
            if(!instancia[field.value]) faltantes.push(field.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`) 

        const invalidos = usuarios.filter(e => e.valid !== true).map(e => e.email)
        if(invalidos.length > 0) return messageApi.error(`Emails inválidos: ${invalidos.join(', ')}`)


        instancia.usuarios = usuarios
        instancia.id_formulario = id_formulario
        setLoading(true)
        return fetch(`${urlapi}/${main_data.slug_module}/tareas`,{
            method:'POST',
            body: JSON.stringify(instancia),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success('Registro creado correctamente')
                setRegistro({})
                setCampos([])
                setOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    const handleCancel = () => {
        setIndexSeleccionado(-1);
    }
    
    const agregarOpcionSelector = () => {
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual[indexSeleccionado].values.push({})
            return actual
        }) 
    }

    const eliminarValorOpcion = (i) => {
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual[indexSeleccionado].values.splice(i, 1)
            return actual
        })
    }

    const handleChangeValorSelector = (e, i) => {
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual[indexSeleccionado].values[i].value = e.target.value
            return actual
        })
    }

    const renderModalContent = (componenteSeleccionado) => {
        if (!componenteSeleccionado) return null;

        switch (componenteSeleccionado.slug) {
            case 'input':
                return <div className="mb-3">
                <Form.Item className="mb-3" label="Valor de ejemplo">
                    <Input type="text" value={componenteSeleccionado.example_value} name="example_value" onChange={handleChange} />
                </Form.Item>
                <Form.Item className="mb-3" label="Valor predeterminado">
                    <Input type="text" value={componenteSeleccionado.default_value} name="default_value" onChange={handleChange} />
                </Form.Item>
            </div>
            case 'select': 
                return <div className="mb-3">
                    <Form.Item className="mb-3" label="Opciones">
                    {
                        componenteSeleccionado.values.map((opcion, i) => {
                            return <Input addonBefore={<FaRegTrashAlt className="hover" onClick={() => i ? eliminarValorOpcion(i) : false } />} className="mb-3" placeholder="Escribe un valor" value={opcion.value} onChange={(e) => handleChangeValorSelector(e, i)} />
                        })
                    }
                    </Form.Item>
                    <Button style={{ width: "100%" }} onClick={() => agregarOpcionSelector()}>AGREGAR OPCIÓN</Button>
                </div>
            case 'checkbox':
                return <div className="mb-3">
                    <Switch checkedChildren="ACTIVO POR DEFECTO" unCheckedChildren="INACTIVO POR DEFECTO" checked={componenteSeleccionado.default_checked === true} onChange={(e) => handleChange({ target: { name: "default_checked", value: e } })} />
                </div>
            default:
                return <div>Este componente no es editable.</div>;
        }
    }

    const agregarElemento = (elm) => {
        const nuevo_elemento = {
            id: "",
            label: "",
            example_value: "",
            default_value: "",
            values : [{}],
            min_max: 0,
            default_checked: false,
            max_val: 0,
            slug : elm.slug,
            id_elemento : elm.id,
            required: false
        }
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual.push(nuevo_elemento)
            return actual
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual[indexSeleccionado][name] = value
            return actual
        })
    }
    
    const handleChangeRegistro = (e) => {
        const { name, value } = e.target
        return setRegistro(prev => {
            let actual = { ...prev }
            actual[name] = value
            return actual
        })
    }

    const mostrarModal = () => {
        let objeto = indexSeleccionado > -1 ? campos[indexSeleccionado] : {}

        return <Modal
            title="Editar Componente"
            open={indexSeleccionado > -1}
            onOk={handleCancel}
            footer={[]}
            onCancel={handleCancel}
        >
            <Form layout="vertical">
            <Form.Item className="mb-3" label="Etiqueta">
            <Input type="text" value={objeto.label} name="label" onChange={handleChange} />
            </Form.Item>
            <Form.Item className="mb-3" label="Texto de ayuda">
            <Input type="text" value={objeto.help_text} name="help_text" onChange={handleChange} />
            </Form.Item>
            {renderModalContent(objeto)}
            <Switch checkedChildren="CAMPO OBLIGATORIO" unCheckedChildren="CAMPO OPCIONAL" checked={objeto.required === true} onChange={(e) => handleChange({ target: { name: "required", value: e } })} />
            </Form>
        </Modal>
    }

    const verConfiguracion = (i) => {
        setIndexSeleccionado(i)
    }

    const eliminarCampo = (i) => {
        setCampos(prev => {
            let actual = [ ...prev ]
            actual.splice(i, 1)
            return actual
        })
    }

    const removerItem = (i) => {
        setUsuarios(prev => {
            let actual = [ ...prev ]
            actual.splice(i, 1)
            return actual
        })
    }

    const onChangeUsuarios = (e,i) => {
        const { name, value } = e.target
        setUsuarios(prev => {
            let actual = [ ...prev ]
            actual[i][name] = value
            if(name === "email") actual[i].valid = validateEmail(value)
            return actual
        })
    }

    const mostrarConductores = () => {
        return <div style={{ marginTop: 10 }}>
        <Title className="mt-0" level={5}>{usuarios.length} Personas seleccionadas</Title>
        <List
  header={false}
  footer={false}
  
  dataSource={usuarios}
  renderItem={(driver, i) => (
    <List.Item>
      <Row gutter={10}>
      <Col md={18}>
          <Title level={4} className="mb-0 mt-0">{driver.titulo}</Title>
        </Col>
        <Col md={6} style={{ textAlign: "right" }}>
          <Button 
            type="dashed" 
            size="middle" 
            style={{ fontSize: 12 }} 
            icon={<DeleteOutlined />} 
            onClick={() => removerItem(i)} 
          >
            REMOVER
          </Button>
        </Col>
      </Row>
    </List.Item>
  )}
/>

        </div>
      }

      const agregarUsuario = (driver) => {

        setUsuarios(prev => {
          let actual = [ ...prev ]
          
          if(Array.isArray(driver) !== false){
            for( const user of driver ){
                user.identificador = `${user.nombres}${user.apellido_p ? ` ${user.apellido_p}` : '' }${user.apellido_m ? ` ${user.apellido_m}` : '' }`
                user.valid = validateEmail(user.email)
                const i = actual.findIndex(u => u._id === user._id)
                if(i === -1) actual.push(user)
              }
            } else {
            const i = actual.findIndex(u => u._id === driver._id)
            driver.identificador = `${driver.nombres}${driver.apellido_p ? ` ${driver.apellido_p}` : '' }${driver.apellido_m ? ` ${driver.apellido_m}` : '' }`
            driver.valid = validateEmail(driver.email)
            if(i < 0) actual.unshift(driver)
            }
          
          return actual
        })
      }

      const agregarUsuarioPersonalizado = async () => {
        const nuevo = {
            identificador: "",
            email: ""
        }

        setUsuarios(prev => {
            let actual = [ ...prev ]
            actual.push(nuevo)
            return actual
        })
      }

    const render = () => {
        if(loadingMaster) return <CargandoTabla />

        const edicionBox = <div>

        <Form layout="vertical">
            
            <Form.Item label="Agrega vehículo a esta tarea" required={true} >
                {/* <ModalSeleccionVehiculos extra_component={<Button onClick={() => agregarUsuarioPersonalizado()}>AGREGAR PERSONALIZADO</Button>} label="AGREGAR CONDUCTORES" hideSeleccionados={true} onSelect={(driver) => agregarUsuario(driver)} /> */}
                {/* <ModalSeleccionVehiculo /> */}
                {mostrarConductores()}
            </Form.Item>
            <Form.Item className="mb-3" label="Mensaje">
                <Input.TextArea type="text" value={registro.descripcion} name="descripcion" onChange={handleChangeRegistro} />
            </Form.Item>
            {/* <Form.Item className="mb-3" label="Fecha de vencimiento">
                <Input type="date" value={registro.vencimiento} name="vencimiento" onChange={handleChangeRegistro} />
            </Form.Item> */}
            <Form.Item className="mb-3" label="Permitir llenar por * días" >
                <Input type="number" value={registro.dias_validez} name="dias_validez" onChange={handleChangeRegistro} />
            </Form.Item>
            <Form.Item className="mb-3" label="Notificar esta tarea">
                <Select name="send" defaultValue={"inmediatamente"} options={[ { value: "inmediatamente", label: "Inmediatamente" }, { value: "1 hora", label: "En 1 hora" }, { value: "2 hors", label: "En 2 horas" }, { value: "manana", label: "Mañana" } ]} />
            </Form.Item>
            <Form.Item className="mb-3" label="Reenviar esta tarea todos los ">
                <Select name="send" 
                allowClear
                options={[ { value: "lunes", label: "Lunes" }, { value: "martes", label: "Martes" }, { value: "miercoles", label: "Miércoles" }, { value: "jueves", label: "Jueves" },{ value: "viernes", label: "Viernes" }, { value: "sabado", label: "Sábado" }, { value: "domingos", label: "Domingos" }, { value: "fecha", label: "Fecha específica del mes" } ]} 
                onChange={(val) => handleChangeRegistro({ target: { name: "resend", value: val } })}
                />
            </Form.Item>
            {
                registro.resend === "fecha" ? <Form.Item className="mb-3" label="Todos los">
                <Input type="number" max={28} min={1} value={registro.recurrencia_dia} name="recurrencia_dia" onChange={handleChangeRegistro} />
            </Form.Item> : false
            }
        </Form>
        {/* <Row gutter={15}>
        <Col md={12}>
        <Card >
        <Title className="mt-0" level={3}>Formulario</Title>
        {
            campos.map((campo, i) => {
                return <Card className="hover" key={`campo-${i}`} size="small" style={{ marginBottom: 10 }} >
                    <Row>
                        <Col>
                            <Row>
                                <Col><Tag className="mt-0 mb-2" style={{ textTransform: "uppercase" }} level={5}>{campo.slug} {campo.required === true ? <b style={{ color: "red" }}>*</b> : false } </Tag></Col>
                                <Col>{ campo.label ? <Title className="mt-0 mb-3" level={5}>{campo.label}</Title> : false }</Col>
                            </Row>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                        <ButtonGroup>
                            <Button size="small" onClick={() => verConfiguracion(i)}>CONFIGURAR</Button>
                            <Button size="small" icon={<FaRegTrashAlt />} onClick={() => eliminarCampo(i)}>ELIMINAR</Button>
                        </ButtonGroup>
                    </Card>
            })
        }
            {mostrarModal()}
        </Card>
        </Col>
        <Col md={12}>
        <Card>
        <Title className="mt-0" level={3}>Elementos</Title>
        <Row gutter={10}>
            {
                elementos.map((elm,i) => {
                    return <Col md={12} className="hover" key={`el-${i}`} style={{ marginBottom: 10 }}>
                        <Card size="small" onClick={() => agregarElemento(elm)} >
                        {elm.icon}
                        <Paragraph style={{ margin: 0 }}><b>{elm.title}</b></Paragraph>
                        </Card>
                    </Col>
                })
            }
        </Row>
        </Card>
        </Col>
        </Row> */}
    </div>

    const items = [
        {
            label: <Paragraph level={6} className="mt-0 mb-0">Configuración</Paragraph>,
            children: edicionBox
        },
        {
            label: <Paragraph level={6} className="mt-0 mb-0">Eventos</Paragraph>,
            children: <ListadoNotificaciones condicion_default={{ id_target: registro._id }} />
        }
    ].map((item,i) => ({ ...item, key: (i+1).toString() }))

        return (
            <div>
                <Tabs defaultActiveKey="1" items={items} />
            </div>
        );
    };

    const showDrawer = () => {
        consultarRegistro()
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }

    return <div>
        <Button onClick={showDrawer}>VER DETALLES</Button>
        <Drawer title="CREAR NUEVA TAREA" onClose={onClose} open={open} size="large" extra={
          <Space>
            <Button disabled={loading} onClick={onClose}>CANCELAR</Button>
            <Button loading={loading} onClick={() => crearRegistro()} type="primary"  >GUARDAR</Button>
          </Space>
        } >
                {render()}    
        </Drawer>
        {contextHolder}
    </div>
};

export default EditarTarea