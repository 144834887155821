import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Divider, Layout, message, Row, Skeleton, Tag } from "antd";
import { url_images, urlapi } from "../../lib/backend/data";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { cerrarSesion } from "../../redux/actions/sesion";
import socket from "../../lib/websockets";
import Structure from "../Structure";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { fechaATexto, fechaATextoSimple, fechaUTCATexto } from "../../lib/helpers/helpers";
import { tipos } from "./data";

const AnalyticDetail = (props) => {
    const {
        processSession,
        id,
        condicion_default
    } = props
    const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [ messagesWaiting, setMessagesWaiting] = useState([])
  const [ total, setTotal ]       = useState(0)
  const session = useSelector(state => state.miusuario)
  const dispatch = useDispatch()
  const sesion = useSelector(state => state.miusuario)
  const [ seleccionado, setSeleccionado ] = useState(false)
  const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
  const [ messageApi, contextHolder] = message.useMessage();
  const messagesEndRef = useRef(null);
  const [ sessions, setSessions ] = useState([])
  const identificador = `ANALYTIC_EVENT_${id}`
  
  const scrollToBottom = () => {
    if(messagesEndRef.current) messagesEndRef.current?.scrollTo({
        top: messagesEndRef.current.scrollHeight,
        behavior: 'smooth',
      });
  };

  const seleccionar = (data) => {
    setSeleccionado(data)
  }

  const obtenerMensaje = async (page, query)=>{
    const condicion = query ? query : condicion_busqueda
    setLoading(true)
    return fetch(`${urlapi}/analytics/events/list`,{
        method:'POST',
        body: JSON.stringify({
            condicion: { id_session: id },
            pagina: page,
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            messageApi.error('Sin datos')
        } else if(res.errorMessage){
            messageApi.error(res.errorMessage)
        } else if(Array.isArray(res.datos) !== false){
            setSessions(res.datos)
            setTotal(res.total)
            setTimeout(() => scrollToBottom(), 400)
        }
        return setLoading(false)
    })
    .catch(error => {
        messageApi.error("Error al consultar la información, intente nuevamente")
        return setLoading(false)
    })
}

const guardarMensaje = async (payload)=>{
    return fetch(`${urlapi}/chat/message`,{
        method:'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
        } else if(res._id){
            setMessages(prev => {
                let actual = [...prev]
                const i = actual.findIndex(x => x.localId === res.localId)
                if(i >= 0) actual[i] = res
                return [...[], ...actual]
            })
        }
    })
    .catch(error => {

    })
}

  const recibirNuevoMensaje = (message) => {
    if(processSession) processSession(message)
    return setSessions(prev => {
        let actual = [...prev]
        const id = message._id
        if(actual.filter(e => e._id === id).length < 1){
            actual.push(message)
        }
        setTimeout(() => scrollToBottom(), 200)
        return actual
    })
  }

    useEffect(() => {
        obtenerMensaje()
        socket.on(identificador, nuevo => {
          return recibirNuevoMensaje(nuevo)
        })
        return () => {
            socket?.off(identificador)
        }

    }, [ id ])

    const renderizarInformacion = (type, data) => {

        const detalleVehiculo = <div>
            <Row gutter={15}>
                        <Col md={12} className="mb-3">
                            <Paragraph className="mb-0 mt-0">TIPO</Paragraph>
                            <Paragraph className="mb-0 mt-0"><b>{data?.analytic_data?.vehiculoSeleccionado?.tipo}</b></Paragraph>
                        </Col>
                        <Col md={12} className="mb-3">
                            <Paragraph className="mb-0 mt-0">MODELO</Paragraph>
                            <Paragraph className="mb-0 mt-0"><b>{data?.analytic_data?.vehiculoSeleccionado?.modelo}</b></Paragraph>
                        </Col>
                        <Col md={12} className="mb-3">
                            <Paragraph className="mb-0 mt-0">MARCA</Paragraph>
                            <Paragraph className="mb-0 mt-0"><b>{data?.analytic_data?.vehiculoSeleccionado?.marca}</b></Paragraph>
                        </Col>
                        <Col md={12} className="mb-3">
                            <Paragraph className="mb-0 mt-0">PATENTE</Paragraph>
                            <Paragraph className="mb-0 mt-0"><b>{data?.analytic_data?.vehiculoSeleccionado?.patente}</b></Paragraph>
                        </Col>
                    </Row>
        </div>
        switch(type){
            case "SEARCH":
                return <div>
                    <Row gutter={15}>
                        <Col md={12}>
                            <Paragraph className="mb-0 mt-0">ORIGEN</Paragraph>
                            <Paragraph className="mb-0 mt-0"><b>{data?.analytic_data?.origin}</b></Paragraph>
                        </Col>
                        <Col md={12}>
                            <Paragraph className="mb-0 mt-0">DESTINO</Paragraph>
                            <Paragraph className="mb-0 mt-0"><b>{data?.analytic_data?.destination}</b></Paragraph>
                        </Col>
                    </Row>
                </div>
            case "CONTINUE_BOOKING":
                return <div>
                    {detalleVehiculo}
                </div>
            case "FINAL_BOOKING_FORM":
                return <div>
                    <Row>
                        <Col md={8} className="mb-3">
                        <Paragraph className="mb-0 mt-0">TIPO DE VIAJE</Paragraph>
                        <Paragraph className="mb-0 mt-0" style={{ textTransform: "uppercase" }}><b>{data?.analytic_data?.quote?.type}</b></Paragraph>
                        </Col>
                        <Col md={8} className="mb-3">
                        <Paragraph className="mb-0 mt-0">TIPO DE CARGA</Paragraph>
                        <Paragraph className="mb-0 mt-0"><b>{data?.analytic_data?.quote?.tipo_carga?.title}</b></Paragraph>
                        </Col>
                        <Col md={8} className="mb-3">
                        <Paragraph className="mb-0 mt-0">PESO BRUTO</Paragraph>
                        <Paragraph className="mb-0 mt-0"><b>{data?.analytic_data?.quote?.peso_bruto}</b></Paragraph>
                        </Col>
                        <Col md={8} className="mb-3">
                        <Paragraph className="mb-0 mt-0">VALOR CARGA</Paragraph>
                        <Paragraph className="mb-0 mt-0"><b>{data?.analytic_data?.quote?.moneda_carga} {data?.analytic_data?.quote?.valor_carga}</b></Paragraph>
                        </Col>
                        <Col md={24} className="mb-3">
                        <Paragraph className="mb-0 mt-0">DESCRIPCIÓN DE LA CARGA</Paragraph>
                        <Paragraph className="mb-0 mt-0"><b>{data?.analytic_data?.quote?.descripcion_carga}</b></Paragraph>
                        </Col>
                    </Row>
                    {detalleVehiculo}
                </div>
            case "OPEN_VEHICLE":
                const vehiculo = Array.isArray(data?.analytic_data) ? data?.analytic_data[0] : false
                return <div>
                    <Row gutter={15}>
                        <Col md={12} className="mb-3">
                            <Paragraph className="mb-0 mt-0">TIPO</Paragraph>
                            <Paragraph className="mb-0 mt-0"><b>{vehiculo?.tipo}</b></Paragraph>
                        </Col>
                        <Col md={12} className="mb-3">
                            <Paragraph className="mb-0 mt-0">MODELO</Paragraph>
                            <Paragraph className="mb-0 mt-0"><b>{vehiculo?.modelo}</b></Paragraph>
                        </Col>
                        <Col md={12} className="mb-3">
                            <Paragraph className="mb-0 mt-0">MARCA</Paragraph>
                            <Paragraph className="mb-0 mt-0"><b>{vehiculo?.marca}</b></Paragraph>
                        </Col>
                        <Col md={12} className="mb-3">
                            <Paragraph className="mb-0 mt-0">PATENTE</Paragraph>
                            <Paragraph className="mb-0 mt-0"><b>{vehiculo?.patente}</b></Paragraph>
                        </Col>
                    </Row>
                </div>
        }
        
    }
    
const mostrarSesiones = () => {
    return <div ref={messagesEndRef} style={{ maxHeight: 500, overflowY: "scroll" }}>
        <Paragraph className="mb-0 mt-0">{sessions.length} eventos recibidos</Paragraph>
        {
            sessions.map((session,i) => {
                const evento = renderizarInformacion(session.type, session)
                return <div className="mb-3 shadow" size="small" key={session._id} >
                    <Paragraph className="mb-0 mt-0">{fechaUTCATexto(session.createdAt)}</Paragraph>
                    <Title level={4} className="mb-0 mt-0">{tipos[session.type] || session.type}</Title>
                    <Paragraph className="mb-0 mt-0" style={{ textTransform: "lowercase" }}>POR <b>{session.usuario?.nombres} {session.usuario?.apellidos}</b></Paragraph>
                    <Tag>A TRAVÉS DE <b>PLATAFORMA CLIENTE</b></Tag>
                    {
                        evento ? <Card  size="small"  className="shadow mt-3">
                        <Title level={4} className="mb-0 mt-0">Detalles del evento</Title>
                        {evento}
                        </Card> : false
                    }
                    
                    { sessions.length === (i+1) ? false : <Divider className="mt-3 mb-3" /> }
                </div>
            })
        }
    </div>
}
  const render = () => {
    if(loading) return <Skeleton />
    return (
        <div>
          {/* Content (Messages List) */}
          <Title level={4} className="mb-0 mt-0">Detalles de la sesión</Title>
         
          {contextHolder}
          {mostrarSesiones()}
        </div>
      );
  }

  const renderizar = <div><Card>{render()}</Card></div>

  return renderizar
};

export default AnalyticDetail