import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Layout, message, Modal, Pagination, Row, Tag } from "antd";
import { pagina, url_images, urlapi } from "../../lib/backend/data";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { cerrarSesion } from "../../redux/actions/sesion";
import socket from "../../lib/websockets";
import Structure from "../Structure";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { fechaATexto, fechaATextoSimple } from "../../lib/helpers/helpers";
import AnalyticDetail from "./detalleAnalytic";

const Analytic = (props) => {
    const {
        condicion_default
    } = props
    const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [ messagesWaiting, setMessagesWaiting] = useState([])
  const [ total, setTotal ]       = useState(0)
  const session = useSelector(state => state.miusuario)
  const [ pagina, setPagina ]     = useState(1)
  const dispatch = useDispatch()
  const sesion = useSelector(state => state.miusuario)
  const [ seleccionado, setSeleccionado ] = useState(false)
  const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
  const [ messageApi, contextHolder] = message.useMessage();
  const messagesEndRef = useRef(null);
  const [ sessions, setSessions ] = useState([])
  const identificador = `ANALYTIC_SEARCH`
  const tipos = {
    "SEARCH": "BÚSQUEDA CLIENTE",
    "CONTINUE_BOOKING": "BOTÓN IR A LA RESERVA",
    "FINAL_BOOKING_FORM": "FORMULARIO FINAL DE RESERVA"
  }


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const seleccionar = (data) => {
    setSeleccionado(data)
  }

  const obtenerMensaje = async (page, query)=>{
    const condicion = query ? query : condicion_busqueda

    return fetch(`${urlapi}/analytics/list`,{
        method:'POST',
        body: JSON.stringify({
            condicion,
            pagina: page,
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            messageApi.error('Sin datos')
        } else if(res.errorMessage){
            messageApi.error(res.errorMessage)
        } else if(Array.isArray(res.datos) !== false){
            setSessions(res.datos)
            setTotal(res.total)
        }
        return setLoading(false)
    })
    .catch(error => {
        messageApi.error("Error al consultar la información, intente nuevamente")
        return setLoading(false)
    })
}

const guardarMensaje = async (payload)=>{
    return fetch(`${urlapi}/chat/message`,{
        method:'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
        } else if(res._id){
            setMessages(prev => {
                let actual = [...prev]
                const i = actual.findIndex(x => x.localId === res.localId)
                if(i >= 0) actual[i] = res
                return [...[], ...actual]
            })
        }
    })
    .catch(error => {

    })
}


  const recibirNuevoMensaje = (message) => {
    console.log(message)
    // if(loading) return setMessagesWaiting(prev => {
    //   let actual = [...prev]
    //   const i = actual.findIndex(x => x._id === message._id)
    //   if(i < 0) actual.push(message)
    //   return [...[], ...actual]
    // })

  }

    useEffect(() => {
        obtenerMensaje(1, null)
        socket.on(identificador, nuevo => {
          return recibirNuevoMensaje(nuevo)
        })
        return () => {
            socket?.off(identificador)
        }

    }, [])

    const renderizarInformacion = (type, data) => {
        switch(type){
            case "SEARCH":
                return <div>
                    <Row gutter={15}>
                        <Col md={12}>
                            <Paragraph className="mb-0 mt-0">ORIGEN</Paragraph>
                            <Paragraph className="mb-0 mt-0"><b>{data?.analytic_data?.origin}</b></Paragraph>
                        </Col>
                    </Row>
                </div>
        }
        
    }

    const processSession = (data) => {
        const { session } = data
        if(!session) return false
        console.log(session)
        const i = sessions.findIndex(e => e._id === session._id)
        if(i < 0) return setSessions(prev => {
            let actual = { ...prev }
            actual.unshift(sesion)
            return actual
        })
    }

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerMensaje(e, condicion_busqueda)
    }


    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination showSizeChanger={false} defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    
const mostrarSesiones = () => {
    return <div>
        <Modal width="70%" id="modal_detail" open={seleccionado !== false} onCancel={() => setSeleccionado(false)}>
            <AnalyticDetail id={seleccionado?._id} processSession={(e) => processSession(e)} />
        </Modal>

        {paginacion(pagina, total)}

        {
            sessions.map(session => {
                return <Card className="mb-3 hover" size="small" key={session._id} onClick={() => seleccionar(session)}>
                    <Paragraph className="mb-0 mt-0">{fechaATexto(session.createdAt)}</Paragraph>
                    <Title level={4} className="mb-0 mt-0">{tipos[session.type] || session.type}</Title>
                    <Paragraph className="mb-0 mt-0" style={{ textTransform: "lowercase" }}>POR <b>{session.usuario?.nombres} {session.usuario?.apellidos}</b></Paragraph>
                    <Tag>A TRAVÉS DE <b>PLATAFORMA CLIENTE</b></Tag>
                    {/* {renderizarInformacion(session.type, session)} */}
                </Card>
            })
        }
    </div>
}
  const render = () => {
    
    return (
        <div>
          {/* Content (Messages List) */}
          <Title level={4} className="mb-0 mt-0">Analítica</Title>
          <Paragraph className="mb-0 mt-0">Sesiones de usuario</Paragraph>

          {contextHolder}
          {mostrarSesiones()}
        </div>
      );
  }

  const renderizar = <div><Card>{render()}</Card></div>

  return <Structure component={renderizar} />
};

export default Analytic