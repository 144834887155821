import React, { useEffect, useState } from "react";
import { Form, Checkbox, Card, message } from "antd";
import CargandoTabla from "../../components/Viajes/cargando";
import { FaFileInvoice } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { urlapi } from "../../lib/backend/data";
import { estilo_moving_truck } from "../../lib/estilo_sitio";
import Title from "antd/es/typography/Title";

const CaracteristicasSeleccion = (props) => {
    const { title, defaultConfig } = props;
    const [ loading, setLoading ] = useState(true)

    const default_data = {
        groups: [
            {
                label: "Requisitos del conductor",
                key: "conductor"
            },
            {
                label: "Requisitos de la carga",
                key: "carga"
            }
        ],
        options: [
            {
                label: "Zapatos de seguridad",
                type: "check",
                group: "conductor"
            },
            {
                label: "Peonetas en lugar de carga",
                type: "check",
                group: "conductor"
            },
            {
                label: "Peonetas en lugar de descarga",
                type: "check",
                group: "conductor"
            },
            {
                label: "Yegua de carga",
                type: "check",
                group: "carga"
            }
        ]
    };

    const [config, setConfig] = useState(default_data)
    const [configSeleccionada, setConfigSeleccionada] = useState(defaultConfig ? defaultConfig : []);
    const [messageApi, contextHolder] = message.useMessage();
    const pais = useSelector((state) => state.pais);

    const obtenerRegistros = async (tipo, subtipo) => {
        return fetch(`${urlapi}/configuracion/details?tipo=${tipo}&subtipo=${subtipo}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'country': pais,
          },
        })
          .then((res) => res.json())
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(res._id){
                if(res.detalles) setConfig(res.detalles)
            }
            return setLoading(false);
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }


      useEffect(() => {
        obtenerRegistros('solicitudes','exigencias')
      }, [])
    const manejarSeleccion = (label) => {
        setConfigSeleccionada((prev) => {
            if (prev.includes(label)) {
                const nuevo = prev.filter((item) => item !== label)
                const configuracion = {
                    esquema: config,
                    seleccionada: nuevo
                }
                if(props.onChange) props.onChange(configuracion)
                return nuevo
            } else {
                const nuevo = [...prev, label]
                const configuracion = {
                    esquema: config,
                    seleccionada: nuevo
                }
                if(props.onChange) props.onChange(configuracion)
                return nuevo
            }
        });
    };

    const mostrarOpciones = () => {
        if(loading) return <CargandoTabla />
        
        const agrupar = {}
        config.groups.forEach((g) => {
            agrupar[g.key] = config.options.filter((o) => o.group === g.key);
        });

        return (<div>
                <Title level={4} className="mb-0" ><FaFileInvoice /> Selecciona los requisitos mínimos para el desarrollo de la logística</Title>
                <Form>
                    {config.groups.map((group) => (
                        <div key={group.key} style={{ paddingBottom: 10}}>
                            <Title level={4}>{group.label}</Title>
                            <Form.Item>
                                {agrupar[group.key].map((option) => (
                                    <Checkbox
                                        key={option.label}
                                        checked={configSeleccionada.includes(option.label)}
                                        onChange={() => manejarSeleccion(option.label)}
                                    >
                                        {option.label}
                                    </Checkbox>
                                ))}
                            </Form.Item>
                        </div>
                    ))}
                </Form>
                </div>
        );
    };

    return (
        <div>
            <Title level={3} className="mt-0 mb-0">{title}</Title>
            {mostrarOpciones()}
        </div>
    );
};

export default CaracteristicasSeleccion;
