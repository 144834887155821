import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { urlapi } from "../../lib/backend/data";
import { Alert, AutoComplete, Button, Col, Form, Input, message, Row, Spin, Table, Tabs, Tag } from "antd";
import { cerrarSesion } from "../../redux/actions/sesion";
import SinInformacion from "../../subComponents/general/sin_informacion";
import Cargando from "../../subComponents/general/cargando";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { fechaATexto, fechaATextoSimpleExperimental, formatYMD_UTC } from "../../lib/helpers/helpers";
import { estilo_moving_truck } from "../../lib/estilo_sitio";
import ProductosVacios from "./productosVacios";
import { FaFileInvoice, FaPowerOff, FaRegChartBar, FaRegStickyNote, FaRegTrashAlt } from "react-icons/fa";
import Column from "antd/es/table/Column";
import { debounce } from 'lodash';
import { LoadingOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { IoIosAttach, IoMdSend } from "react-icons/io";
import { convertirSlug, formatoMoneda } from "../../lib/helpers/main";
import MediosBox from "../../subComponents/medios/medios";
import { MdOutlineNotes } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import VisualizarCotizacion from "./visualizarCotizacion";
import ButtonGroup from "antd/es/button/button-group";
import EsquemaPrecios from "../Viajes/esquemaPrecios";

const PoliticasCondiciones = () => (
    <div style={{  }}>
      <Title level={1}>Políticas y Condiciones del Servicio de Transporte</Title>
  
      <Title level={2}>1. Generalidades</Title>
      <Paragraph>
        Estas políticas y condiciones regulan el uso de nuestros servicios de transporte. Al contratar y utilizar nuestros servicios, el usuario acepta de manera explícita los términos aquí descritos. Nos reservamos el derecho de actualizar o modificar estas políticas en cualquier momento, con o sin previo aviso, y serán efectivas a partir de su publicación en nuestros canales oficiales.
      </Paragraph>
  
      <Title level={2}>2. Responsabilidades del Usuario</Title>
      <Paragraph>
        El usuario se compromete a proporcionar información precisa y actualizada al solicitar el servicio, incluyendo direcciones exactas y detalles sobre la carga, en caso de ser aplicable. Cualquier error en la información suministrada que derive en retrasos o costos adicionales será responsabilidad exclusiva del usuario.
      </Paragraph>
      <Paragraph>
        El usuario deberá asegurarse de que la carga a transportar esté adecuadamente empacada y etiquetada, en cumplimiento con las normativas vigentes. No se transportarán bienes que infrinjan la ley, materiales peligrosos o cualquier otro artículo prohibido.
      </Paragraph>
  
      <Title level={2}>3. Responsabilidades del Prestador del Servicio</Title>
      <Paragraph>
        Nuestro servicio de transporte garantiza la máxima diligencia en la manipulación y traslado de los bienes. Nos comprometemos a cumplir con los tiempos de entrega acordados, siempre y cuando no existan factores externos como condiciones climáticas adversas, tráfico u otras eventualidades de fuerza mayor que puedan afectar la operación.
      </Paragraph>
      <Paragraph>
        No nos hacemos responsables por demoras atribuibles a causas externas o situaciones ajenas a nuestro control, incluyendo pero no limitándose a: huelgas, accidentes, desastres naturales, conflictos sociales, entre otros.
      </Paragraph>
  
      <Title level={2}>4. Seguro y Protección de la Carga</Title>
      <Paragraph>
        Ofrecemos un seguro básico para la carga transportada, cubriendo daños o pérdidas que puedan ocurrir durante el servicio de transporte y que sean atribuibles a nuestra negligencia. Para mercancías de alto valor o delicadas, recomendamos la contratación de un seguro adicional que brinde cobertura completa. No asumimos responsabilidad por la pérdida o daño de bienes no declarados o no asegurados debidamente por el usuario.
      </Paragraph>
  
      <Title level={2}>5. Tarifas y Pagos</Title>
      <Paragraph>
        Las tarifas se calculan con base en la distancia, volumen, tipo de carga y otros factores que se comunicarán al usuario antes de la contratación del servicio. Cualquier cambio en la ruta o en las condiciones del servicio que implique costos adicionales será comunicado y deberá ser aprobado por el usuario antes de proceder.
      </Paragraph>
  
      <Title level={2}>6. Cancelaciones y Reembolsos</Title>
      <Paragraph>
        El usuario podrá cancelar el servicio sin penalización con un aviso de al menos 24 horas antes de la fecha programada para el transporte. En caso de cancelación posterior, se aplicará una penalización correspondiente al 50% del valor del servicio. No se otorgarán reembolsos por servicios ya prestados o cancelados una vez iniciado el proceso de transporte.
      </Paragraph>
  
      <Title level={2}>7. Modificaciones del Servicio</Title>
      <Paragraph>
        Nos reservamos el derecho de modificar las rutas, los horarios y cualquier aspecto operativo del servicio para garantizar la seguridad y eficacia del mismo. Cualquier modificación sustancial será informada al usuario con antelación.
      </Paragraph>
  
      <Title level={2}>8. Reclamaciones</Title>
      <Paragraph>
        Las reclamaciones por pérdidas o daños deberán ser presentadas por escrito en un plazo no mayor a 48 horas tras la entrega de la carga. Después de este período, no se aceptarán reclamaciones. Todas las reclamaciones serán evaluadas y, en caso de proceder, se gestionarán las compensaciones correspondientes según lo establecido en el seguro.
      </Paragraph>
  
      <Title level={2}>9. Privacidad y Protección de Datos</Title>
      <Paragraph>
        El usuario consiente el uso de sus datos personales para la correcta prestación del servicio. Garantizamos que todos los datos recolectados serán tratados de acuerdo con las leyes vigentes sobre protección de datos y no serán compartidos con terceros sin el consentimiento expreso del usuario, salvo por obligación legal.
      </Paragraph>
  
      <Title level={2}>10. Limitaciones de Responsabilidad</Title>
      <Paragraph>
        No seremos responsables por ningún tipo de daño indirecto, incidental, especial o consecuente que resulte del uso o la imposibilidad de uso de nuestros servicios, incluyendo pérdida de ganancias o interrupción del negocio.
      </Paragraph>
  
      <Title level={2}>11. Legislación Aplicable</Title>
      <Paragraph>
        Estas políticas y condiciones se rigen por la legislación vigente en el país de operación del servicio. Cualquier controversia que surja en relación con el servicio de transporte será sometida a la jurisdicción de los tribunales competentes del país.
      </Paragraph>
    </div>
  );

const VisualizarCrearCotizacion = (props) => {
    const {
        id_solicitud,
        ids_origin,
        ids_destination
    } = props
    const [ loading, setLoading ]   = useState(true)
    const [options, setOptions] = useState([
        { value: "Transporte" },
        { value: "Seguro de carga" },
        { value: "Peonetas" },
    ]);
    const [ newOptions, setNewOptions ] = useState([]);
    const [ saving, setSaving ]     = useState(false)
    const [ sugerencias, setSugerencias ] = useState([ ...options, ...newOptions.map(e => ({ value: e.label })) ])
    const dispatch                  = useDispatch()
    const sesion                    = useSelector(state => state.miusuario)
    const tokenSession              = sesion.tokenSession
    const [ quote, setQuote ]       = useState(null)
    const [messageApi, contextHolder] = message.useMessage()
    const module                    = 'cotizaciones'
    const mockVal = (str, repeat = 1) => ({
        value: str.repeat(repeat),
      });
    const getPanelValue = (searchText) => !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)];

    const guardar = async (payload, refresh, anular) =>{
        if(refresh){
            if(!anular){
                if(!payload.expiration_date) return messageApi.error('Debe ingresar una fecha de expiración')
                if(payload.items.length === 0) return messageApi.error('Debe ingresar al menos un producto')
            }
        }
        setSaving(true)
        return fetch(`${urlapi}/${module}`,{
            method:'PUT',
            body: JSON.stringify({ ...payload, emit: refresh }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                if(refresh){
                    messageApi.success('¡Realizado exitosamente!')
                    setQuote(prev => {
                        let actual = {...prev} 
                        return { ...actual, public: res.public }
                    })
                }
            }
            return setSaving(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setSaving(false)
        })
    }

    const obtenerRegistro = async ()=>{
        if(!id_solicitud) return
        setLoading(true)
        return fetch(`${urlapi}/${module}/get?idsolicitud=${id_solicitud}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                setQuote(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const handleChangeQuote = (e) => {
        const { name, value } = e.target;
        setQuote(prev => {
            let actual = { ...prev };
            guardarAutomaticamente({ ...actual, [name]: value });
            return { ...actual, [name]: value };
        });
    }

    const handleChangeAutocomplete = (text, i) => {
        setQuote(prev => {
            let actual = { ...prev }
            let productos = Array.isArray(actual.items) ? [...actual.items] : [];
            productos[i] = { ...productos[i], titulo: text };
            return { ...actual, items: productos };
        })
    }

    const handleChangePro = (e, i) => {
        const { name, value } = e.target;
        setQuote(prev => {
            let actual = { ...prev };
            let productos = Array.isArray(actual.items) ? [...actual.items] : [];
            productos[i] = { ...productos[i], [name]: value };
            guardarAutomaticamente({ ...actual, items: productos });
            return { ...actual, items: productos };
        });
    };

    const obtenerSugerencias = async ()=>{
        return fetch(`${urlapi}/configuracion/tipo/international?tipo=cotizaciones&subtipo=sugerencias`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(res?._id){
                if(Array.isArray(res?.detalles?.options)){
                    console.log(res?.detalles?.options)
                    setSugerencias(res?.detalles?.options)
                }
            }
        })
        .catch(error => console.log(error.message))
    }

    useEffect(() => {
        obtenerRegistro()
        obtenerSugerencias()
    }, [])

    const guardarAutomaticamente = useCallback(debounce((data) => guardar(data), 1000), []);

    const guardarNuevaOpcion = (text) => {
        return
        setNewOptions(prev => {
            let actual = [...prev]
            const slug = convertirSlug(text)
            const i = actual.findIndex(x => x.slug === slug)
            if(i < 0) actual.unshift({ label: text, value: slug })
            return [...[], ...actual]
        })
    }

    const onSelect = (value, i) => {
        const name = 'titulo'
        setQuote(prev => {
            let actual = { ...prev };
            let productos = Array.isArray(actual.items) ? [...actual.items] : [];
            productos[i] = { ...productos[i], [name]: value };
            guardarNuevaOpcion(value)
            guardarAutomaticamente({ ...actual, items: productos });
            return { ...actual, items: productos };
        });
      };

    const anadirProducto = () => {
        setQuote(prev => {
            let actual = { ...prev }
            const nuevosItems = actual.items ? [...actual.items, {}] : [{}]
            return { ...actual, items: nuevosItems };
        });
    };

    const eliminarItem = (i) => {
        setQuote(prev => {
            let actual = { ...prev }
            const nuevosItems = actual.items.filter((item, index) => index !== i)
            guardarAutomaticamente({ ...actual, items: nuevosItems });
            return { ...actual, items: nuevosItems };
        });
    }

    const mostrarUltimaVersionEnviada = () => {
        if(!quote.public) return null
        return <Tag color="blue">Última versión enviada al cliente el {fechaATexto(quote.public.sendDate)}</Tag>
    }

    const footer = () => {
        const subtotal = quote.items.reduce((acc, item) => {
            const cantidad = parseFloat(item.cantidad) || 0;
            const precio = parseFloat(item.precio) || 0;
            const subtotal = cantidad * precio;
            return acc + subtotal;
        } , 0)
        const impuesto = quote.taxRate ? quote.taxRate : 19
        const factorImpuesto = 1 + (impuesto / 100);
        const valorImpuesto = impuesto / 100
        const total = subtotal * factorImpuesto
        const taxRate = quote.taxRate ? quote.taxRate : 19

        return <div style={{ fontWeight: "bold" }}>
        <Row>
            <Col md={12}><Button onClick={() => anadirProducto()}>AÑADIR PRODUCTO</Button></Col>
            <Col md={9} style={{ textAlign: "right" }}>
                <Paragraph><b>SUB TOTAL</b></Paragraph>
                <Paragraph><b>IMPUESTO {taxRate}%</b></Paragraph>
                <Paragraph><b>TOTAL</b></Paragraph>
            </Col>
            <Col md={3} style={{ textAlign: "right" }}>
                <Paragraph>{formatoMoneda(subtotal)}</Paragraph>
                <Paragraph>{formatoMoneda(subtotal * valorImpuesto)}</Paragraph>
                <Paragraph>{formatoMoneda(total)}</Paragraph>
            </Col>
        </Row>
    </div>
    }

    if(loading) return <Cargando />
    if(!quote) return <div style={{ textAlign: "center" }}><SinInformacion /></div>

    let cotizacion_publica_ = quote.public ? { ...quote.public } : ""
    if(cotizacion_publica_){
        delete cotizacion_publica_.public
        delete cotizacion_publica_.sendDate
        cotizacion_publica_ = JSON.stringify(cotizacion_publica_)
    }

    let cotizacion_actual = { ...quote}
    delete cotizacion_actual.public
    if(cotizacion_actual) cotizacion_actual = JSON.stringify(cotizacion_actual)

    const diferentes = cotizacion_actual === cotizacion_publica_
    
    const detalles_cotizacion = <div>
        <Row gutter={15}>
            <Col md={12}>
                <Tag><b>Creada por {quote.creador?.nombres}</b> el {fechaATextoSimpleExperimental(quote.createdAt)}</Tag>
            </Col>
            <Col md={12} style={{ textAlign: "right" }}>
            {saving ? <Title level={4} className="mt-0 mb-0">GUARDANDO... <Spin indicator={<LoadingOutlined spin />} size="large" /></Title> : <Title level={4} className="mt-0 mb-0">GUARDADO</Title> }
            {mostrarUltimaVersionEnviada()}
            </Col>
        </Row>
        
        <Form layout="vertical">

        <Row gutter={15} className="mt-3">
            <Col md={4}>
                <Form.Item label="Válido hasta" name="expiration_date">
                <Input type="date" name="expiration_date" defaultValue={quote.expiration_date ? formatYMD_UTC(quote.expiration_date) : ""} onChange={handleChangeQuote} />
                </Form.Item>
            </Col>
            <Col md={4}>
                <Form.Item label="Taza Impuesto" >
                <Input type="number" disabled value={quote.taxRate ? quote.taxRate : 19} />
                </Form.Item>
            </Col>
        
        </Row>
        
       

        <Table size="large"  className="mt-3" footer={footer} dataSource={quote.items} pagination={false} bordered  locale={{ emptyText: <ProductosVacios /> }} >
                <Column title="Título" render={(item,o,i) => {
                    return <div><AutoComplete options={sugerencias} style={{ minWidth: 200}} name="titulo" value={item.titulo} placeholder="Describe el producto" onSearch={(text) => handleChangeAutocomplete(text, i)} onSelect={(e) => onSelect(e, i)} /> </div>
                }} />
                <Column title="Descripción" render={(item,o,i) => {
                    return <div><Input placeholder="Descripción del item" value={item.descripcion} name="descripcion" onChange={(e) => handleChangePro(e,i)} /> </div>
                }} />
                <Column title="Cantidad" render={(item,o,i) => {
                    return <div><Input name="cantidad" type="number" value={item.cantidad} onChange={(e) => handleChangePro(e,i)} /></div>
                }} />
                <Column title="Precio" render={(item,o,i) => {
                    return <div><Input name="precio" value={item.precio} type="number" onChange={(e) => handleChangePro(e,i)} /></div>
                }} />
                <Column title="Sub total" render={(item) => {
                    
                    const cantidad = parseFloat(item.cantidad) || 0;
                    const precio = parseFloat(item.precio) || 0;
                    const subtotal = cantidad * precio;

                    return <div><Input disabled type="number" value={subtotal.toFixed(0)} /></div>
                }} />
                <Column title="Eliminar" render={(item,o,i) => {
                    return <div><Button onClick={() => eliminarItem(i)} ><FaRegTrashAlt /></Button> </div>
                }} />
        </Table>
            
        <Row gutter={15} className="mt-3">
            
            <Col md={24}>
                <Form.Item label={ <Title level={3} className="mt-0 mb-0"><FaRegStickyNote /> Notas </Title> }>
                <Input name="notes" placeholder="Escribe tus comentarios" defaultValue={quote.notes} onChange={handleChangeQuote} />
                </Form.Item>
            </Col>
        </Row>

        </Form>

        <ButtonGroup size="large">
        <Button className="mt-3" disabled={saving || diferentes } style={{ width: "100%" }} onClick={() => guardar({ ...quote, public: { ...quote, sendDate: new Date } }, true)} icon={<IoMdSend style={{ verticalAlign: "middle" }} />} > ENVIAR AL CLIENTE</Button>
        <Button className="mt-3" disabled={saving} style={{ width: "100%" }} onClick={() => guardar({ ...quote, public: { ...quote, sendDate: new Date, null: true } }, true, true)} icon={<FaPowerOff style={{ verticalAlign: "middle" }} />} >ANULAR VERSIÓN DEL CLIENTE</Button>
        <Button className="mt-3" disabled={saving} style={{ width: "100%" }} onClick={() => guardar({ ...quote, public: null }, true, true)} icon={<FaRegTrashAlt style={{ verticalAlign: "middle" }} />}> ELIMINAR VERSIÓN DEL CLIENTE</Button>
        </ButtonGroup>
        
    </div>

    const medios_cotizacion = <div>
        <MediosBox id_destino={quote._id} />
    </div>

    const items = [
        {
            label: <Title level={4} className="mt-0 mb-0"><FaFileInvoice /> Detalles</Title>,
            children: detalles_cotizacion
        },
        // {
        //     label: <Title level={4} className="mt-0 mb-0"><IoIosAttach /> Documentos adjuntos</Title>,
        //     children: medios_cotizacion
        // },
        {
            label: <Title level={4} className="mt-0 mb-0"><MdOutlineNotes /> Políticas y condiciones</Title>,
            children: <div>
                <Alert message={<div><Title level={4} className="mt-0 mb-0">Estas políticas son administradas por la plataforma</Title> </div>} type="info" />
                <PoliticasCondiciones />
            </div>
        },
        {
            label: <Title level={4} className="mt-0 mb-0"><IoShareSocialOutline /> Versión cliente</Title>,
            children: <div>
                <Alert message={<div><Title level={4} className="mt-0 mb-0">Vista previa de la cotización para el cliente</Title> </div>} type="info" />
                <div style={{ marginTop: 40 }}><VisualizarCotizacion quote={quote.public} /></div>
            </div>
        },
        {
            label: <Title level={4} className="mt-0 mb-0"><FaRegChartBar /> Tendencia de precios</Title>,
            children: <div>
                <EsquemaPrecios ids_origin={Array.isArray(ids_origin) ? ids_origin : []} ids_destination={Array.isArray(ids_destination) ? ids_destination : []} />
            </div>
        },
    ].map((item, index) => ({ ...item, key: `item-${index}` }))

    return <div>
        <Title level={2} className="mt-0 mb-0">Cotización {quote.id} </Title>
        <Tabs items={items} />
        {contextHolder}
    </div>
}

export default VisualizarCrearCotizacion;