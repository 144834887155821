import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { Alert, Button, Checkbox, Col, Divider, Form, Input, Layout, Modal, Pagination, Rate, Row, Table, Tag, Tooltip, message } from "antd"
import { fechaATextoExperimental, fechaUTCATexto } from "../../lib/helpers/helpers"
import { FileExcelFilled, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores"
import ModalPersonalizado from "../../subComponents/general/modales/modal_personalizado"
// import SelectorGeneral from "../General/selector"
// import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
// import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado"
import HelperModal from "../../subComponents/general/helperModal"
import TagSinDatos from "../../subComponents/general/tag_sin_datos"
import Cargando from "../general/cargando"
import { formatoMoneda } from "../../lib/helpers/main"
import { infoByStatusPayment, stringByStatusPayment } from "./data"
import { IoIosHelpCircle } from "react-icons/io"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import SelectorFechas from "../general/selector_fechas"
import { cerrarSesion } from "../../redux/actions/sesion"
const { Column } = Table

const ListadoPagos = (props)=> {
    const {
        condicion_default,
        showFilters,
        showCustomFieldsData,
        showCounters,
        hideSearch,
        showSelection
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ conductores, setRegistros] = useState([])
    const [ loadingRegistros, setLoadingRegistros] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ key, setKey ] = useState(0)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const pais = useSelector(state => state.pais)
    const trash                     = props.trash ? props.trash : false
    const idioma                    = useSelector(state => state.idioma)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const tipo                      = props.tipo ? props.tipo : "link"
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const titulo                    = props.title ? props.title : 'registros'
    const module = 'pagos'
    const key_interfaz              = 'tabla_calificaciones'
    const key_esquema               = 'CALIFICACIONES'
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const exclude = []

    const deseleccionarTodos = (e) => {
        setRegistros([...[], ...conductores.map(veh => { 
            if(exclude){
                if( Array.isArray(exclude) !== false ){
                    if(exclude.includes(veh._id)) return veh
                }
            }
            veh.seleccionado = e.target.checked
            return veh 
        })])
    }

    const checkForEnabledValue = (id) => {
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      
      
    const obtenerDatos = async (page, query)=>{
        setLoadingRegistros(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${module}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingRegistros(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingRegistros(false)
            } else if(Array.isArray(res.datos) !== false){
                setRegistros(res.datos)
                setTotal(res.total)
            }
            return setLoadingRegistros(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingRegistros(false)
        })
    }

    useEffect(() => {
        obtenerDatos(1, false)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerDatos(e, condicion_busqueda)
    }
    
    const refrescarBusqueda = () => {
        setPagina(1)
        let cond = {}
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            }
        })
        obtenerDatos(1, cond)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Row gutter={15}>
                {/* <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col> */}
                {/* <Col span="auto"><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green"  onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col> */}
            </Row>
            
            <h5 level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></h5>
            <Pagination current={pagina} total={(cantidad*10)} showSizeChanger={false} onChange={handleChangePagina} />
        </div>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/clients`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setPagina(1)
            setCondicionBusqueda({})
            return obtenerDatos(1, {})
        }
        const condicion = {
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerDatos(1, condicion);
      };

      const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

      
    const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit} >
        {/* <h5 className="mb-0 mt-0" level={5}>BUSCAR</h5> */}
        <Row gutter={10}>
            <Col md={20}>
                <Form.Item className="mb-2">
                    <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Escribe numero de solicitud o cotización`} />
                </Form.Item>
            </Col>
            <Col md={4}>
                <Form.Item >
                    <Button  onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                </Form.Item>
            </Col>
        </Row>
                </Form>
      }

      const eliminarMultiples = async ()=>{
        setLoadingRegistros(true)

        const seleccionados = conductores.filter(e => e.seleccionado).map(e => e._id)
        return fetch(`${urlapi}/${module}/masive`,{
            method:'DELETE',
            body: JSON.stringify({
                ids: seleccionados
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingRegistros(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingRegistros(false)
            } else if(res.deletedCount){
                return window.location.reload()
            }
            return setLoadingRegistros(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingRegistros(false)
        })
    }


      const accionesEspeciales = () => {
        return false
        const seleccionados = conductores.filter(e => e.seleccionado)
        return <div style={{ marginBottom: 20 }}>
            <Button disabled={seleccionados.length < 1} loading={loadingRegistros} style={{ marginRight: 10 }} size="small" onClick={() => eliminarMultiples()} >ELIMINAR</Button>
        </div>
      }

      const handleChangeSeleccion = (e) => {
        const { name } = e.target
        const valor = e.target.checked
        setRegistros(prev => {
            let actual = [...prev]
            const i = actual.findIndex(ve => ve._id === name )
            actual[i].seleccionado = valor
            return actual
        })
      }

      const validarChecked = (seleccionado, id) => {
        if(seleccionado === true) return true
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const mostrarTabla = () => {
        if(loadingRegistros) return <Cargando />

        const component_titulo = <Column title="Título" render={(data) => {
                    return <div>{data.titulo}</div>
        }} />

        const component_ref_viaje = <Column title="Ref Viaje" render={(data) => {
                    return <div>{data.internal_id}</div>
        }} />

        const component_monto = <Column title="Monto" render={(data) => {
            return <div>{formatoMoneda(data.monto)}</div>
        }} />
        
        const component_moneda = <Column title="Moneda" render={(data) => {
            return <div>CLP</div>
        }} />

        const component_detalles = <Column title="Detalles" render={(data) => {

            const MostrarInfo = (props) => {
                const {
                    data
                } = props
                const [isModalOpen, setIsModalOpen] = useState(false);

                if(!data) return <TagSinDatos />

                return <div>
                        <Modal
                        // width="80%"
                        footer={[]}
                        title={"Detalles de calificación"}
                        open={isModalOpen} 
                        onCancel={() => setIsModalOpen(false)}
                    >   
                    <div>
                        <Tag className="mb-3">Fecha de la operación {fechaATextoExperimental(data.createdAt)}</Tag>
                       <Row gutter={15}>
                        <Col md={12}>
                            <h5>Referencia</h5>
                            <p>{data.titulo}</p>
                        </Col>
                        <Col md={12}>
                            <h5>Identificador de viaje</h5>
                            <h3>{data.internal_id}</h3>
                        </Col>
                        <Col xs={24}><Divider /></Col>
                        <Col md={6}>
                            <h5>Moneda</h5>
                            <p>CLP</p>
                        </Col>
                        <Col md={6}>
                            <h5>Monto</h5>
                            <p>{data.monto}</p>
                        </Col>
                        <Col md={12}>
                            <h5>Estado del pago</h5>
                            <p><Tooltip title={infoByStatusPayment(data.status)}>
                            {stringByStatusPayment(data.status)} <IoIosHelpCircle />
                            </Tooltip></p>
                        </Col>
                        <Col xs={24}><Divider /></Col>
                        <Col xs={24}>
                        <h5>Estado de facturación</h5>
                        <p><Tooltip title="Sin factura asociada, una vez facturado verás aquí el documento fiscal emitido, esto podría demorar dependiendo del país de facturación">Pendiente <IoIosHelpCircle /></Tooltip></p>
                        </Col>
                       </Row>
                    </div>
                    </Modal>
                    <Button size="small" type="primary" onClick={() => setIsModalOpen(true)} style={{ textTransform: "uppercase" }} >{"VER DETALLES"}</Button>

                </div>
            }

            return <div>
                <MostrarInfo data={data} /> </div>
        }} />
        
        const component_status = <Column title="Estado" render={(data) => {
                    return <div>
                        <Tooltip title={infoByStatusPayment(data.status)}>
                        {stringByStatusPayment(data.status)} <IoIosHelpCircle />
                        </Tooltip>
                        </div>
        }} />
        
        const component_status_facturado = <Column title="Facturado" render={(data) => {
            return <div>
                <Tooltip title="Sin factura asociada">Pendiente <IoIosHelpCircle /></Tooltip>
            </div>
        }} />
        const component_fecha = <Column title="Fecha" render={(data) => {
            return <div>{fechaUTCATexto(data.createdAt)}</div>
        }} />

          return <Table dataSource={conductores} tableLayout="fixed" pagination={false} size="small" bordered scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="pagos" /> }}>
            { showSelection === true ? <Column title={<Checkbox onChange={deseleccionarTodos} />} render={(data) => {
                return <Checkbox disabled={checkForEnabledValue(data._id)} checked={validarChecked(data.seleccionado, data._id)} name={data._id} onChange={handleChangeSeleccion}/>
            }} /> : false }
            {/* { encontrarLlaveValorEnEsquema("estrellas", interfaz_usuario[ key_interfaz ]) === false ? false : component_titulo } */}
            {/* { encontrarLlaveValorEnEsquema("comentario", interfaz_usuario[ key_interfaz ]) === false ? false : component_comentario } */}
            {/* { encontrarLlaveValorEnEsquema("detalles", interfaz_usuario[ key_interfaz ]) === false ? false : component_detalles } */}
            {/* { encontrarLlaveValorEnEsquema("empresa", interfaz_usuario[ key_interfaz ]) === false ? false : component_empresa } */}
            {/* { encontrarLlaveValorEnEsquema("createdAt", interfaz_usuario[ key_interfaz ]) === false ? false : component_fecha } */}
            {component_titulo}
            {component_detalles}
            {component_ref_viaje}
            {component_moneda}
            {component_monto}
            {component_status}
            {component_status_facturado}
            {/* {component_detalles} */}
            {component_fecha}
      </Table>
      }

      const agregarFiltro = (key, value) => {
        filtroBusqueda[key] = { $in: value.map(v => v._id) }
        console.log(filtroBusqueda)
        setFiltroBusqueda(filtroBusqueda)
      }

      const resetFilters = () => {
        setFiltroBusqueda({})
        setKeyInputFilter(key_input_filter + 1)
        obtenerDatos(1, condicion_default ? condicion_default : {})
    }

      const mostrarFiltrosBusqueda = () => {
        return false
        /*
        const mostrarButtonReset = Object.keys(filtroBusqueda).length > 0 ? true : false
        return <div style={{ marginBottom: 20 }} className="mt-0">
            <Row gutter={10}>
                <Col md={6} className="mb-3">
                    <h5 className="mt-0" level={5}>Categoría</h5>
                    <SelectorGeneral key={key_input_filter} hideLabel={true} mode="multiple" module="categorias" titulo="Categoría" condicion_default={{ tipo: "cliente" }} onChange={(data) => agregarFiltro('id_categoria', data)} />
                </Col>
                <Col md={6} className="mb-3">
                    <h5 className="mt-0" level={5}>Sub categoría</h5>
                    <SelectorGeneral key={key_input_filter} hideLabel={true} mode="multiple" module="sub-categorias" titulo="Categoría" condicion_default={{ tipo: "cliente" }} onChange={(data) => agregarFiltro('id_sub_categoria', data)} />
                </Col>
                <Col md={6} className="mb-3">
                    <h5 className="mt-0" level={5}>Filtrar</h5>
                    <Button loading={loadingRegistros} onClick={() => refrescarBusqueda()} style={{ width: "100%" }} icon={<SearchOutlined />} >ACTUALIZAR</Button>
                </Col>
                <Col md={6} className="mb-3">
                    <h5 className="mb-2 mt-0" level={5}>FILTRAR</h5>
                    <Button disabled={!mostrarButtonReset}  style={{ width: "100%" }} onClick={() => resetFilters()}>BORRAR FILTROS</Button>
                </Col>
            </Row>
        </div>
        */
      }

      const mostrarContadores = () => {
        return false
      }
      
      const mostrarDatosPersonalizados = () => {
        const component = <GraficosCamposPersonalizados typeView="modal" tipo="cliente" />
        return showCustomFieldsData === true ? <Col><ModalPersonalizado typeView="modal" slug="informe_personalizado" component={component} /></Col> : false
      }

      const actualizarBusqueda = (condicion) => {
        setCondicionBusqueda(prev => {
            let actual = {...prev, ...condicion}
            if(!condicion.createdAt) delete actual.createdAt
            const cond = {}
            obtenerDatos(1, {...cond, ...actual})
            return {...{}, ...actual}
        
        })
    }

    const mostrarRegistros = () => {

        return <div>
            <SelectorFechas key={key} onChange={(data) => actualizarBusqueda(data)} />
            {
                showFilters === true ? <Row gutter={15}>
                <Col md={24}>
                    {mostrarFiltrosBusqueda()}
                    {buscador()}
                </Col>
                <Col md={24}>
                    <Row gutter={15} className="mb-3">
                    {mostrarContadores()}
                    {mostrarDatosPersonalizados()}
                    </Row>
                    {paginacion(data.pagina, total)}
                    {accionesEspeciales()}
                    {mostrarTabla()}
                </Col>
            </Row> : <div>
                    {mostrarContadores()}
                    {buscador()}
                    {paginacion(data.pagina, total)}
                    {accionesEspeciales()}
                    {mostrarTabla()}
                </div>
            }
            
            
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoPagos