// const default_menu = {
//     collapse_menu: false
// }
import { Esquema } from "../../lib/esquemas/tablas"

const tabla_campos_personalizados = Esquema.CAMPOS_PERSONALIZADOS.map(ob => ({...ob, active: true }))
const tabla_categorias = Esquema.CATEGORIAS.map(ob => ({...ob, active: true }))
const tabla_sub_categorias = Esquema.SUBCATEGORIAS.map(ob => ({...ob, active: true }))
const tabla_bodegas = Esquema.BODEGAS.map(ob => ({...ob, active: true }))
const tabla_contactos = Esquema.CONTACTOS.map(ob => ({...ob, active: true }))
const tabla_equipos = Esquema.EQUIPOS.map(ob => ({...ob, active: true }))
const tabla_vehiculos = Esquema.VEHICULOS.map(ob => ({...ob, active: true }))
const tabla_conductores = Esquema.CONDUCTORES.map(ob => ({...ob, active: true }))
const tabla_viajes = Esquema.VIAJES.map(ob => ({...ob, active: true }))
const tabla_rutas = Esquema.RUTAS.map(ob => ({...ob, active: true }))
const tabla_productos = Esquema.PRODUCTOS.map(ob => ({...ob, active: true }))
const tabla_costos = Esquema.COSTOS.map(ob => ({...ob, active: true }))
const tabla_disponibilidades = Esquema.DISPONIBILIDAD.map(ob => ({...ob, active: true }))
const tabla_disponibilidades_solicitudes = Esquema.DISPONIBILIDAD_SOLICITUDES.map(ob => ({...ob, active: true }))
const tabla_calificaciones = Esquema.CALIFICACIONES.map(ob => ({...ob, active: true }))
const tabla_canales = Esquema.CANALES.map(ob => ({...ob, active: true }))
const tabla_blog = Esquema.BLOG.map(ob => ({...ob, active: true }))

const data_default = {
    tabla_campos_personalizados,
    tabla_contactos,
    tabla_bodegas,
    tabla_categorias,
    tabla_sub_categorias,
    tour_dismiss: false,
    collapse_menu: false,
    tabla_vehiculos,
    tabla_conductores,
    tabla_viajes,
    tabla_equipos,
    tabla_rutas,
    tabla_productos,
    tabla_costos,
    tabla_disponibilidades,
    tabla_disponibilidades_solicitudes,
    tabla_calificaciones,
    tabla_canales,
    tabla_blog,
    tours: {
        routing: true
    },
    sort_config: {
        viajes: {
            sort_column: '_id',
            sort_order: 'desc'
        },
        vehiculos: {
            sort_column: '_id',
            sort_order: 'desc'
        }
    },
    limit_pagination: {
        viajes: {
            limit_page: 20
        }
    },
}

function reducer( state = data_default, { type, payload } ){
    switch( type ){
        case 'modificarEsquema': {
            return payload
        }
        case 'reiniciarEsquema': {
            return data_default
        }
        default:
            return state
    }
}

export default reducer