import React, { Component, useEffect, useState } from 'react'
import { validateEmail } from '../../lib/helpers/helpers'
import { Link, Navigate, useParams } from 'react-router-dom'
import { Button, Card, Col, Divider, Form, Input, Result, Row, Select, Spin, Switch, message } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Title from 'antd/es/typography/Title'
import Paragraph from 'antd/es/typography/Paragraph'
import { gradient_primary } from '../../lib/global/style'
import { rutas } from '../../lib/routes/routes'
import { urlapi } from '../../lib/backend/data'
import { BsCursorText } from 'react-icons/bs'
import { FaRegEnvelope } from 'react-icons/fa'
import { AiOutlineMail } from 'react-icons/ai'
import CargaImagen from '../../subComponents/medios/carga_imagen'
import { useQuery } from '../../lib/helpers/router'

const FormularioLlenadoPublico = (props) => {
    const {
        id
    } = useParams()
    const [ email, setEmail ] = useState('')
    const [ campos, setCampos ] = useState([])
    const [ password, setPassword ] = useState('')
    const [ codigo, setCodigo ] = useState('')
    const [ registro, setRegistro ] = useState(false)
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ loading, setLoading ] = useState(false)
    const [ success, setSuccess ] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const query = useQuery()
    useEffect(() => {
        consultarInformacion()
    }, [])

    const obtenerID = (val, regla) => {
        const split = val.toString().split("-")
        return regla === true ? split[ split.length - 1 ] : split[ split.length - 2 ]
    }
    const consultarInformacion = () => {
        if(!id) return setLoadingMaster(false)
        const id_consulta = obtenerID(id)
        if(!id_consulta) return setLoadingMaster(false)
        return fetch(`${urlapi}/formularios/fill`, {
            method: "POST",
            body: JSON.stringify({
                id: id_consulta,
                id_target: query.get("user")
            }),
            headers: {
                'Content-type': "application/json"
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.message){
                messageApi.error(res.message)
            } else if(res._id){
                setRegistro(res)
                if(res.formulario) setCampos(res.formulario)
            }
            setLoadingMaster(false)
        })
        .catch(error => {
            setLoadingMaster(false)
        })
    }

    const handleSubmit = async (e) => {
        if(e) e.preventDefault()
        

        const invalidos = campos.filter(campo => campo.required === true && !campo.value)
        if(invalidos.length > 0 ) messageApi.error(`Faltan campos por llenar, ${invalidos.map(c => c.label).join(', ')}`)

        setLoading(true)
        const id_regla = obtenerID(id, true)
        const instancia = { id_formulario: registro._id, entradas: campos, id_regla, id_target: query.get("user") }
        return fetch(`${urlapi}/formularios/entradas`, {
            method: "POST",
            body: JSON.stringify(instancia),
            headers: {
                'Content-type': "application/json"
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                setSuccess(res._id)
            }
            setLoading(false)
        })
        .catch(error => {
            setLoading(false)
        })
    }

    const handleChangeEmail = (e) => {
        const { value } = e.target
        setEmail(value)
    }

    const handleChangePassword = (e) => {
        const { value } = e.target
        setPassword(value)
    }

    
    const MensajeEnviado = () => {
        
        return <div>
            <Result
                status="success"
                title="Todo listo"
                subTitle="Gracias por enviar la información, ha sido recibida exitosamente"
                extra={[
                <Button type="primary" key="console">
                    <Link to={'/'}>Volver al inicio</Link>
                </Button>
                ]}
            />
        </div>
    }

    const handleChange = (value, i, key) => {
        console.log({value, i, key})
        setCampos(prev => {
            const actual = [ ...prev ]
            if(key === "value"){
                actual[i][key] = value
            } else if(key === "checked"){
                actual[i][key] = value
            }
            return [ ...actual ]
        })
    }

    const componentByType = (campo, i) => {
        switch (campo.slug) {
            case 'input':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                    <Input placeholder={campo.example_value} addonBefore={<BsCursorText />} onChange={(e) => handleChange(e?.target?.value, i, 'value')} type="text" />
                </Form.Item>
            case 'email':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                    <Input addonBefore={<FaRegEnvelope /> } type="email" onChange={(e) => handleChange(e.target.value, i, 'value')} />
                    </Form.Item>
            case 'texto':
                return <Paragraph className="mt-0 mb-0">{campo.label}</Paragraph>
            case 'title':
                return <Title level={3} className='mb-0 mt-0'>{campo.label}</Title>
            case 'archivo':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                    <CargaImagen onChange={(url) => handleChange(url, i, 'value')} />
                    { campo.value ? <Paragraph className="mt-0 mb-0">Archivo cargado</Paragraph> : false }
                    </Form.Item>
            case 'divisor':
                return <Divider />
            case 'select':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                    <Select placeholder={campo.example_value} onChange={(e) => handleChange(e, i, 'value')}>
                    {
                        campo.values.map((opcion, i) => {
                            return <Select.Option key={`opcion-${i}`} value={opcion.value}>{opcion.value}</Select.Option>
                        })
                    }
                </Select>
                    </Form.Item>
            case 'checkbox':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                    <Switch onChange={e => handleChange(e, i, 'checked')} />
                    </Form.Item>
            case 'datepicker':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                <Input type="date" onChange={(e) => handleChange(e.target.value, i, 'value')} />
                </Form.Item>
            default:
                return <div>Este componente no es editable.</div>
        }
    }


    const formulario = () => {
        if(loadingMaster) return <Spin />
        if(success) return <MensajeEnviado onChange={(val) => setCodigo(val)} />
        if(!registro) return <div></div>

        return <div>
            <Title level={4} className='mt-0 mb-3'>{registro?.title}</Title>
            {mostrrarTarget()}
            <Form layout="vertical">
            {/* <Form.Item label="Tu email" required={true} className="mb-3">
            <Input placeholder='Escribe aquí tu correo electrónico' prefix={<AiOutlineMail />} className='mb-0' name="email" onChange={handleChangeEmail} />
            </Form.Item> */}
            {
                campos.map((campo, i) => {
                    return <div className="mb-3" key={`campo-${i}`} style={{ marginBottom: 10 }} >
                        {componentByType(campo, i)}
                        </div>
                })
            }
            <Button loading={loading} style={{ width: "100%" }} type='primary' onClick={() => handleSubmit()} >ENVIAR</Button>
            </Form>
        </div>
    }
    const mostrrarTarget = () => {
        if(!registro.tarjet) return <div>
            <Title level={4} className='mt-0 mb-0'>No está disponible el recurso a asociar</Title>
        </div>

        return <Card size="small" className='mb-3'>
            <Title level={5} className='mt-0 mb-0'>Recurso asociado</Title>
            <Title level={4} className='mt-0 mb-0'>{registro.tarjet?.detalle_modelo} {registro.tarjet?.patente}</Title>
        </Card>
    }
    
    return <div >
        {contextHolder}
        <Row style={{ height: "100vh" }}>
        <Col md={8} style={{ padding: 30, alignItems: "center" }}>
        {formulario()}
        </Col>
        <Col md={16} xs={0} style={{ background: gradient_primary, display: "flex", alignItems: "center"}}>
            <img style={{ width: "55%", margin: "0 auto"}} className='hidden-mobile' src="/images/3.png" />
        </Col>
        </Row>
    </div>
}

export default FormularioLlenadoPublico