import React, { useEffect, useState } from 'react';
import { Badge, Breadcrumb, Button, Card, Col, Divider, Layout, Menu, Row, notification, theme } from 'antd';
import Title from 'antd/es/typography/Title';
import { Navigate, Link as RouterLink, redirect, useLocation } from "react-router-dom";
import { rutas } from '../../lib/routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { consultarNotificaciones, marcarNotificacionesLeidas } from '../../redux/actions/notificaciones';
import { IoAnalyticsOutline, IoNotifications } from 'react-icons/io5';
import socket from '../../lib/websockets';
import { cerrarSesion } from '../../redux/actions/sesion';
import { AlertOutlined, CarOutlined, IdcardOutlined, LogoutOutlined, MonitorOutlined, SecurityScanOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { AiOutlineHome, AiOutlineMenu } from 'react-icons/ai'
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'
import { modificarInterfaz } from '../../redux/actions/interfaz';
import { Footer } from 'antd/es/layout/layout';
import Paragraph from 'antd/es/typography/Paragraph';
import { BiTrip } from 'react-icons/bi';
import PorcentajeProgresoEmpresa from '../Empresa/porcentaje';
import { FaRegCreditCard } from 'react-icons/fa';

const { Content, Sider } = Layout;
const styleButton = { color: "black"}

const Structure = (props) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [api, contextHolderPush] = notification.useNotification();
  const dispatch = useDispatch()
  const session = useSelector(state => state.miusuario)
  const notificaciones = useSelector(state => state.notificaciones)
  const interfaz = useSelector(state => state.interfaz)
  const [collapsed, setCollapsed] = useState(interfaz.collapse_menu === true ? true : false);
  const defaultSelectedKeys = []
  const defaultCollapsed = []
  const { pathname } = useLocation()
  const items_menu_principal = [
    {
        icon: <AiOutlineHome />,
        key: "inicio",
        label: <RouterLink to={"/"}>INICIO</RouterLink>
    },
    {
        icon: <MonitorOutlined />,
        key: "torre-control",
        label: "TORRE DE CONTROL",
        children: [
            // {
            //     label: <RouterLink to={rutas.monitoring.slug} >MONITOREO CONDUCTORES</RouterLink>,
            //     key: rutas.monitoring.slug
            // },
            // {
            //     label: <RouterLink to={rutas.monitoring_vehicles.slug} >MONITOREO VEHÍCULOS</RouterLink>,
            //     key: rutas.monitoring_vehicles.slug
            // },
            {
                label: <RouterLink to={rutas.monitoring_general.slug} >MONITOREO</RouterLink>,
                key: rutas.monitoring_general.slug
            },
            {
                label: <RouterLink to={rutas.map_alerts.slug} >GEO ALERTAS</RouterLink>,
                key: rutas.map_alerts.slug
            },
            {
                key: rutas.tracking_driver.slug,
                label: <RouterLink to={rutas.tracking_driver.slug} >AUDITORIA</RouterLink>,
            },
            {
                key: rutas.estadistica_tracking.slug,
                label: <RouterLink to={rutas.estadistica_tracking.slug} >INFORME DE RASTREO</RouterLink>,
            },
            {
                key: rutas.auditoria_pedidos.slug,
                label: <RouterLink to={rutas.auditoria_pedidos.slug} >EVENTOS DE APP</RouterLink>,
            },
            {
                key: rutas.auditoria_conductores.slug,
                label: <RouterLink to={rutas.auditoria_conductores.slug} >AUDITORÍA DE CONDUCTORES</RouterLink>,
            },
        ]
    },

    // {
    //     icon: <AlertOutlined />,
    //     label: "GEO ALERTAS",
    //     children: [
    //         {
    //             key: rutas.map_alerts.slug,
    //             label: <RouterLink to={rutas.map_alerts.slug} >MAPA</RouterLink>,
    //             key: rutas.map_alerts.slug
    //         },
    //         {
    //             key: rutas.alerts.slug,
    //             label: <RouterLink to={rutas.alerts.slug} >LISTADO</RouterLink>,
    //             key: rutas.alerts.slug
    //         },
    //     ]
    // },
    {
        icon: <FaRegCreditCard />,
        label: "FINANCIERO",
        children: [
            {
                key: rutas.costos.slug,
                label: <RouterLink to={rutas.costos.slug} >COSTOS</RouterLink>,
                key: rutas.costos.slug
            }
        ]
    },
    // {
    //     icon: <IdcardOutlined />,
    //     label: rutas.products.title,
    //     children: [
    //         {
    //             key: rutas.products.slug,
    //             label: <RouterLink to={rutas.products.slug} >LISTADO</RouterLink>,
    //             key: rutas.products.slug
    //         },
    //         {
    //             key: rutas.products_categories.slug,
    //             label: <RouterLink to={rutas.products_categories.slug} >{rutas.products_categories.title}</RouterLink>,
    //             key: rutas.products_categories.slug
    //         },
    //         {
    //             key: rutas.products_subcategories.slug,
    //             label: <RouterLink to={rutas.products_subcategories.slug} >{rutas.products_subcategories.title}</RouterLink>,
    //             key: rutas.products_subcategories.slug
    //         },
    //     ]
    // },
    {
        icon: <CarOutlined />,
        label: "DISPONIBILIDAD",
        children: [
            {
                key: rutas.busqueda_recursos.slug,
                label: <RouterLink to={rutas.busqueda_recursos.slug} >{rutas.busqueda_recursos.title}</RouterLink>,
            },
            {
                key: rutas.solicitud_disponibilidad.slug,
                label: <RouterLink to={rutas.solicitud_disponibilidad.slug} >{rutas.solicitud_disponibilidad.title}</RouterLink>,
            },
            {
                key: rutas.solicitud_disponibilidad_externa.slug,
                label: <RouterLink to={rutas.solicitud_disponibilidad_externa.slug} >{rutas.solicitud_disponibilidad_externa.title}</RouterLink>,
            },
            {
                key: rutas.custom_fields.slug,
                label: <RouterLink to={rutas.disponibilidades.slug} >{rutas.disponibilidades.title}</RouterLink>,
            },
            {
                key: rutas.payments.slug,
                label: <RouterLink to={rutas.payments.slug} >{rutas.payments.title}</RouterLink>,
            },
        ]
    },
    {
        icon: <CarOutlined />,
        label: "FLOTA",
        children: [
            {
                key: rutas.vehicles.slug,
                label: <RouterLink to={rutas.vehicles.slug} >{rutas.vehicles.title}</RouterLink>
            },
            {
                key: rutas.drivers.slug,
                label: <RouterLink to={rutas.drivers.slug} >{rutas.drivers.title}</RouterLink>,
            },
            {
                key: rutas.equipment.slug,
                label: <RouterLink to={rutas.equipment.slug} >{rutas.equipment.title}</RouterLink>,
            },
            {
                key: rutas.ckeck_list.slug,
                label: <RouterLink to={rutas.ckeck_list.slug} >{rutas.ckeck_list.title}</RouterLink>,
            },
            // {
            //     key: rutas.equipment_categories.slug,
            //     label: <RouterLink to={rutas.equipment_categories.slug} >{rutas.equipment_categories.title}</RouterLink>,
            //     key: rutas.equipment_categories.slug
            // },
            // {
            //     key: rutas.equipment_sub_categories.slug,
            //     label: <RouterLink to={rutas.equipment_sub_categories.slug} >{rutas.equipment_sub_categories.title}</RouterLink>,
            //     key: rutas.equipment_sub_categories.slug
            // },
        ]
    },
    {
        icon: <IdcardOutlined />,
        label: "CLIENTES",
        children: [
            {
                key: rutas.clients.slug,
                label: <RouterLink to={rutas.clients.slug} >{rutas.clients.title}</RouterLink>,
            },
            {
                key: rutas.contacts.slug,
                label: <RouterLink to={rutas.contacts.slug} >{rutas.contacts.title}</RouterLink>,
            },
            
            {
                key: rutas.warehouses.slug,
                label: <RouterLink to={rutas.warehouses.slug} >{rutas.warehouses.title}</RouterLink>,
            },
            {
                key: rutas.products.slug,
                label: <RouterLink to={rutas.products.slug} >{rutas.products.title}</RouterLink>,
            },
            {
                key:"gestion-clientes",
                label: "GESTIÓN DE CLIENTES",
                children: [
                    {
                        icon: <UserOutlined />,
                        key: rutas.estados_carga.slug,
                        label: <RouterLink to={rutas.estados_carga.slug} >{rutas.estados_carga.title}</RouterLink>
                    },
                    {
                        icon: <UserOutlined />,
                        key: rutas.tipos_servicio.slug,
                        label: <RouterLink to={rutas.tipos_servicio.slug} >{rutas.tipos_servicio.title}</RouterLink>
                    },
                ]
            },
            // {
            //     key: rutas.clients_sub_categories.slug,
            //     label: <RouterLink to={rutas.clients_sub_categories.slug} >{rutas.clients_sub_categories.title}</RouterLink>,
            //     key: rutas.clients_sub_categories.slug
            // },
            // {
            //     key: rutas.cargo_categories.slug,
            //     label: <RouterLink to={rutas.cargo_categories.slug} >{rutas.cargo_categories.title}</RouterLink>,
            //     key: rutas.cargo_categories.slug
            // },
        ]
    },
    // {
    //     icon: <IdcardOutlined />,
    //     label: "CONDUCTORES",
    //     children: [

    //         {
    //             key: rutas.actividad_flota.slug,
    //             label: <RouterLink to={rutas.actividad_flota.slug} >EVENTOS</RouterLink>,
    //             key: rutas.actividad_flota.slug
    //         },
    //     ]
    // },
    {
        icon: <BiTrip />,
        label: "VIAJES",
        children: [
            {
                key: rutas.trips.slug,
                label: <RouterLink to={rutas.trips.slug} >{rutas.trips.title}</RouterLink>,
                key: rutas.trips.slug
            },
            {
                key: rutas.rutas.slug,
                label: <RouterLink to={rutas.rutas.slug} >{rutas.rutas.title}</RouterLink>,
                key: rutas.rutas.slug
            },
            {
                key: rutas.trips_report.slug,
                label: <RouterLink to={rutas.trips_report.slug} >{rutas.trips_report.title}</RouterLink>,
                key: rutas.trips_report.slug
            },
            {
                key: rutas.postulaciones.slug,
                label: <RouterLink to={rutas.postulaciones.slug} >{rutas.postulaciones.title}</RouterLink>,
            },
            {
                key: "tracking",
                label: "TRACKING",
                children: [
                    {
                        key: rutas.timeline.slug,
                        label: <RouterLink to={rutas.timeline.slug} >{rutas.timeline.title}</RouterLink>,
                    },
                    {
                        key: rutas.eta.slug,
                        label: <RouterLink to={rutas.eta.slug} >{rutas.eta.title}</RouterLink>,
                    },
                ]
            },
            {
                key: "auditoria",
                label: "AUDITORÍA",
                children: [
                    {
                        key: rutas.torre_control_config.slug,
                        label: <RouterLink to={rutas.torre_control_config.slug} style={{ textTransform: "uppercase" }}>{rutas.torre_control_config.title}</RouterLink>,
                    }
                ]
            },
            {
                key: rutas.bodega_expediciones.slug,
                label: <RouterLink to={rutas.bodega_expediciones.slug} >{rutas.bodega_expediciones.title}</RouterLink>,
            },
        ]
    },
    {
        icon: <SettingOutlined />,
        label: "CONFIGURACIÓN",
        children: [
            {
                key: rutas.usuarios.slug,
                label: <RouterLink to={rutas.usuarios.slug} >USUARIOS</RouterLink>
            },
            {
                key: rutas.webhooks.slug,
                label: <RouterLink to={rutas.webhooks.slug} >WEBHOOKS</RouterLink>
            },
            {
                key: rutas.api_keys.slug,
                label: <RouterLink to={rutas.api_keys.slug} >API KEYS</RouterLink>
            },
            {
                key: rutas.zones.slug,
                label: <RouterLink to={rutas.zones.slug} >AUTOMATIZACIONES</RouterLink>,
            },
            {
                key: rutas.custom_fields.slug,
                label: <RouterLink to={rutas.custom_fields.slug} >CAMPOS PERSONALIZADOS</RouterLink>,
            },
            {
                key: rutas.endpoints.slug,
                label: <RouterLink to={rutas.endpoints.slug} >ENDPOINTS</RouterLink>,
            },
            {
                key: rutas.canales.slug,
                label: <RouterLink to={rutas.canales.slug} >{rutas.canales.title}</RouterLink>,
            },
            {
                key: rutas.personalizar.slug,
                label: <RouterLink to={rutas.personalizar.slug} >{rutas.personalizar.title}</RouterLink>,
            },
            {
                key: rutas.notifications_config.slug,
                label: <RouterLink to={rutas.notifications_config.slug} >{rutas.notifications_config.title}</RouterLink>,
            },
            {
                key: "categorias",
                label: "CATEGORÍAS",
                children: [
                    {
                        key: rutas.costos_categories.slug,
                        label: <RouterLink to={rutas.costos_categories.slug} >FINANCIERO</RouterLink>,
                    },
                    {
                        key: rutas.products_categories.slug,
                        label: <RouterLink to={rutas.products_categories.slug} >{rutas.products.title}</RouterLink>,
                    },
                    {
                        key: rutas.equipment_categories.slug,
                        label: <RouterLink to={rutas.equipment_categories.slug} >{rutas.equipment.title}</RouterLink>,
                    },
                    {
                        key: rutas.clients_categories.slug,
                        label: <RouterLink to={rutas.clients_categories.slug} >{rutas.clients.title}</RouterLink>,
                    },
                ]
            },
        ]
    },
    {
        icon: <IoAnalyticsOutline />,
        key: "analytics",
        label: <RouterLink to={"/analytics"}>ANALITICA</RouterLink>
    },
].map((item,it) => {

    const llave = (it+1).toString()

    if(pathname === (item.key)) defaultSelectedKeys.push(llave)

    if(item.children){
        item.children.map((hijo,ih) => {
            defaultCollapsed.push(llave)
            const llave_hijo = `${llave}${ih}`
            if(pathname === (hijo.key)) defaultSelectedKeys.push(llave_hijo)
            item.children[ih].key = llave_hijo
        })
    }
    return {...item, key: llave }
})

  const {
    component
} = props

const cambiarColapso = () => {
    const valor = collapsed === true ? false : true
    setCollapsed(valor)
    const datos_interfaz = JSON.parse( JSON.stringify(interfaz) )
    datos_interfaz.collapse_menu = valor
    return dispatch(modificarInterfaz(datos_interfaz, session.tokenSession))
}

const procesarNotificacion = (datos) => {
    dispatch(marcarNotificacionesLeidas([datos._id], session.tokenSession))
    api.info({
      message: datos.mensaje,
      placement: "bottomRight",
    })
  }

  useEffect(() => {
    dispatch(consultarNotificaciones(session.tokenSession))
    const identificador = `evento_${session.data.propietario}`
    socket.on(identificador, procesarNotificacion)
    return () => {
      socket.off(identificador, procesarNotificacion)
    }
  }, [])

  const logotipo = (<Row gutter={10}>
    <Col md={20}><Title style={{ marginTop: 3, fontSize: 19 }}>MOVING TRUCK 7.71.4</Title></Col>
    <Col md={4} style={{ textAlign: "right" }}><BsArrowLeftShort style={{ fontSize: 30 }} onClick={() => cambiarColapso()} /></Col>
  </Row>)


return (
    <Layout>
      <Layout>
        <Sider
          width={250}
          style={{
            background: colorBgContainer,
            padding: 10
          }}
          collapsed={collapsed}
        >

            { collapsed === true ? <div style={{ textAlign: "center" }}><BsArrowRightShort style={{ fontSize: 30 }} onClick={() => cambiarColapso()} /></div> : logotipo }
          <div style={{ marginTop: 10 }}></div>
          <RouterLink to={rutas.notifications.slug}>
          { collapsed === true ? <div style={{ textAlign: "center" }}><Badge style={{ marginTop: -12 }} count={notificaciones} ></Badge></div> : <div><IoNotifications style={{ color: "black", fontSize: 20 }} /><Badge style={{ marginTop: -12 }} count={notificaciones} ></Badge></div> }
          </RouterLink>
          <Divider className='mt-2 mb-2' />
          <Menu
            mode="vertical"
            triggerSubMenuAction='hover'
            style={{
                marginTop: 10,
                borderRight: 0,
            }}
            defaultSelectedKeys={defaultSelectedKeys}
            items={items_menu_principal}
          />
            <div style={{ paddingLeft: 20, paddingRight: 20 }} className='mb-3'>
                <Card size="small" style={{ marginBottom: 20 }}><PorcentajeProgresoEmpresa /></Card>
                <Button style={{ width: "100%" }} icon={<LogoutOutlined />} onClick={() => dispatch(cerrarSesion())}>CERRAR SESIÓN</Button>
            </div>
        </Sider>
        <Layout
          style={{
            padding: '0px',
            minHeight: '100vh',
          }}
        >
          <Content
            style={{
              padding: 0,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
            }}
          >
            {component}
          </Content>
        </Layout>
      </Layout>
      <Footer>
      <Paragraph style={{ fontSize: 13, color: "gray" }} className="mb-0"><SecurityScanOutlined/> En Moving Truck, la seguridad de su flota es nuestra máxima preocupación. Nuestro software utiliza los más altos estándares de seguridad para proteger sus datos y asegurar un control confiable de su transporte. Confíe en nosotros para una gestión de flota segura y eficiente en cada etapa del proceso.</Paragraph>
      </Footer>
    </Layout>
  );
};

export default Structure