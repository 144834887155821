import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Card, Checkbox, Col, Form, Input, Pagination, Popconfirm, Row, Table, Tag, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import { fechaATextoSimple } from "../../lib/helpers/helpers"
import { FileExcelFilled, SearchOutlined } from "@ant-design/icons"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado"
import EditarContactoRapido from "./editar"
import MostrarEmails from "../../lib/helpers/mostrar_emails"
import MostrarMoviles from "../../lib/helpers/mostrar_moviles"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { PiCursorClickFill } from 'react-icons/pi'
import ModalPersonalizado from "../../subComponents/general/modales/modal_personalizado"
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores"
import SelectorClientes from "../Clientes/selector"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import SelectorGeneral from "../General/selector"
import HelperModal from "../../subComponents/general/helperModal"
import { Esquema } from "../../lib/esquemas/tablas"
import { main_data } from "./data"
import { formatoMoneda } from "../../lib/helpers/main"
import GraficosCostos from "./grafico_costos"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6"
import VerPost from "./editar"
import { estilo_last_mile } from "../../lib/global/styles"

const ListadoRegistros = (props)=> {
    const {
        condicion_default,
        showCustomFieldsData,
        subTitle,
        hideSearch,
        showFilters,
        showSelection,
        exclude,
        hideActions,
        typeView,
        componentAction,
        loadingActions,
    } = props
    const dispatch = useDispatch()
    const sesion                    = useSelector(state => state.miusuario)
    const [ postSelected, setPostSelected ] = useState(false)
    const [conductores, setConductores] = useState([])
    const [deleting, setDeleting]   = useState(false)
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ]     = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ]       = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const pais                      = useSelector(state => state.pais)
    const session                   = useSelector(state => state.miusuario)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const idioma                    = useSelector(state => state.idioma)
    const trash                     = props.trash ? props.trash : false
    const tipo                      = props.tipo ? props.tipo : "link"
    const titulo                    = props.title ? props.title : 'registros'
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const key_interfaz              = main_data.key_interfaz
    const key_esquema               = main_data.key_esquema
    const module                    = main_data.module
    const [ key_input_filter, setKeyInputFilter ] = useState(0)

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/${main_data.module}`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const obtenerRegistros = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${module}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    const eliminarRecurso = async (id) => {
        setDeleting(true)
        return fetch(`${urlapi}/${module}?id=${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(res._id){
              messageApi.success("Eliminado exitosamente")
              obtenerRegistros(1, false)
            }
            return setDeleting(false)
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setDeleting(false)
          })
      }
  

    useEffect(() => {
        const cond = condicion_default ? condicion_default : false
        obtenerRegistros(1, cond)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerRegistros(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3 mt-3">
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination showSizeChanger={false} defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const deseleccionarTodos = (e) => {
        setConductores([...[], ...conductores.map(veh => { 
            if(exclude){
                if( Array.isArray(exclude) !== false ){
                    if(exclude.includes(veh._id)) return veh
                }
            }
            veh.seleccionado = e.target.checked
            return veh 
        })])
      }

      const checkForEnabledValue = (id) => {
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const handleChangeSeleccion = (e) => {
        const { name } = e.target
        const valor = e.target.checked
        const i = conductores.findIndex(ve => ve._id === name )
        conductores[i].seleccionado = valor
        setConductores([...[], ...conductores])
      }

      const validarChecked = (seleccionado, id) => {
        if(seleccionado === true) return true
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const seleccionarRegistro = (data, action) => {
        deseleccionarTodos({ target: false })
        let datos = data
        if(Array.isArray(datos) !== true) datos = [data]
        if(props.onSelect) props.onSelect(datos, action)
    }


    const seleccionarEspecial = (action) => {
        return seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true), action)
      }

      const accionButtons = () => {
        if(showSelection !== true) return false
        const seleccionados = conductores.filter(veh => veh.seleccionado === true).length
        const enable = seleccionados > 0 ? false : true

        if(componentAction){
            return <div style={{ marginTop: 10, marginBottom: 10 }} onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>
                {componentAction.map(it => {
                    return <Button disabled={enable} loading={ loadingActions === true } size="small" style={{ marginRight: 10 }} onClick={() => seleccionarEspecial(it.label)} >{it.label}</Button>
                })}
            </div>
        }


        return <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Row gutter={15}>
                <Col span="auto"><Button disabled={enable} size="small" onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>SELECCIONAR</Button></Col>
                <Col span="auto"><Title level={5} className="mt-0 mb-0"><PiCursorClickFill /> Toca el título para seleccionar uno</Title></Col>
            </Row>
        </div>
      }

      const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <b className="hover" onClick={() => seleccionarRegistro(data)}>{data.identificador ? data.identificador.substring(0, 20) : 'SIN INFORMACIÓN'}</b>
            default:
                return <Link to={`${rutas.products.slug}/${data._id}`}>{data.identificador ? data.identificador.substring(0, 20) : "Sin información"}</Link>
        }
    }

    const getModuleByKey = (key, modulos) => {
        const i = modulos.findIndex(e => e.key === key)
        return i > -1 ? modulos[i].component : false
    }

    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />

        const component_descripcion = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="descripcion" />} render={(data) => {
            return <div>{showByType(typeView, data)}</div>
        }} />

        const component_tipo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tipo" />} render={(data) => {
            return <div>{data.tipo}</div>
        }} />
        const component_subtipo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="subtipo" />} render={(data) => {
            return <div>{data.subtipo}</div>
        }} />

        const component_tarifa = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tarifa" />} render={(data) => {
            return <div>{formatoMoneda(data.tarifa)}</div>
        }} />
        const component_tarifa_confirmada = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tarifa_confirmada" />} render={(data) => {
            return <div>{formatoMoneda(data.tarifa_confirmada)}</div>
        }} />
        const component_id_cliente = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="id_cliente" />} render={(data) => {
            return <div>{data.id_cliente}</div>
        }} />
        
        const component_fecha = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="createdAt" />} render={(data) => {
            return <div>{fechaATextoSimple(data.createdAt)}</div>
        }} />

        const modulos = [
            { key: "descripcion", component: component_descripcion },
            { key: "tipo", component: component_tipo },
            { key: "subtipo", component: component_subtipo },
            { key: "tarifa", component: component_tarifa },
            { key: "tarifa_confirmada", component: component_tarifa_confirmada },
            { key: "id_cliente", component: component_id_cliente },
            { key: "createdAt", component: component_fecha }
        ]

        const acciones_botones = <Column title="Acciones" fixed="right" ellipsis render={(data) => {
            return <div>
                <Row gutter={15}>
                    <Col span="auto"><EditarContactoRapido id_registro={data._id} onCreate={() => obtenerRegistros(1, false)} /></Col>
                    <Col span="auto">
                    <Popconfirm
            title="Eliminar"
            description="¿Estás seguro que deseas eliminar recurso? Esta acción no se puede deshacer"
            onConfirm={() => eliminarRecurso(data._id)}
            onCancel={() => false}
            okText="SI"
            style={{ display: "inline-block" }}
            cancelText="NO"
          >
          <Button loading={deleting} size="small" className="m-0" type="dashed" danger>ELIMINAR</Button>
          </Popconfirm>
                    </Col>
                </Row>
                
          </div>
        }} />

        if(postSelected) return <div>
                <Button className="mb-3" style={{ textTransform: "uppercase" }} onClick={() => setPostSelected(null)} icon={<FaAngleLeft />}> VOLVER {`${subTitle ? `A ${subTitle} ` : ""}`}</Button>
                <Title level={2} className="mt-0 mb-2">{postSelected?.titulo}</Title>
                <VerPost id_registro={postSelected?._id} />
        </div>

        return <div>
            {accionButtons()}
            <Row gutter={15}>
                {
                    conductores.map((data) => {
                        return <Col md={24} key={data._id}>
                            <Card size="small" className="mb-2 hover"  onClick={() => setPostSelected(data)}>
                                <Row gutter={15}>
                                    <Col xs={18}><Title level={4} className="mt-0 mb-0"><FaAngleRight style={estilo_last_mile.icon} /> {data.titulo}</Title></Col>
                                    <Col xs={6} style={{ textAlign: "right" }}>{data.category}</Col>
                                </Row>
                            </Card>
                        </Col>
                    })
                }
            </Row>
            {paginacion(data.pagina, total)}

            {/* <Table dataSource={conductores} pagination={false} size="small"  bordered scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="costos" /> }} >
            {
            showSelection !== true ? false : <Column title={<Checkbox onChange={deseleccionarTodos} />} render={(data) => {
                return <Checkbox disabled={checkForEnabledValue(data._id)} checked={validarChecked(data.seleccionado, data._id)} name={data._id} onChange={handleChangeSeleccion}/>
            }} />
            }
                {
                    Esquema[key_esquema].map(e => {
                        return encontrarLlaveValorEnEsquema(e.key, interfaz_usuario[ key_interfaz ]) === false ? false : getModuleByKey(e.key, modulos)
                    })
                }
                { hideActions === true ? false : acciones_botones }
                
            </Table> */}
        </div>
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setCondicionBusqueda(condicion_default);
            setPagina(1);
            return obtenerRegistros(1, condicion_default);
        }
        const condicion = {
            ...condicion_default,
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerRegistros(1, condicion);
      };

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

    const buscador = () => {
        if(hideSearch === true) return false
        return <Col span="auto"><Form onSubmitCapture={handleSubmit}>
                    <Row gutter={15}>
                        <Col md={14}>
                            <Form.Item >
                                <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Buscar por palabras clave`} />
                            </Form.Item>
                        </Col>
                        <Col md={10}>
                            <Form.Item>
                                <Button onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
        </Col>
      }

      const agregarFiltro = (key, value) => {
        filtroBusqueda[key] = { $in: value.map(v => v._id) }
        console.log(filtroBusqueda)
        setFiltroBusqueda(filtroBusqueda)
      }

      const refrescarBusqueda = () => {
        setPagina(1)
        let cond = {}
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            }
        })
        obtenerRegistros(1, cond)
    }

    const resetFilters = () => {
        setFiltroBusqueda({})
        setKeyInputFilter(key_input_filter + 1)
        obtenerRegistros(1, condicion_default ? condicion_default : {})
    }
    
      const mostrarDatosPersonalizados = () => {
        const component = <GraficosCamposPersonalizados typeView="modaxl" tipo="costos" />
        return showCustomFieldsData === true ? <Col><ModalPersonalizado typeView="modal" slug="informe_personalizado" component={component} /></Col> : false
      }
      
      const mostrarGraficos = () => {
        const component = <GraficosCostos condicion_default={condicion_busqueda} />
        return showCustomFieldsData === true ? <Col><ModalPersonalizado typeView="modal" slug="graficos" component={component} /></Col> : false
      }

      const mostrarFiltrosBusqueda = () => {

        const mostrarButtonReset = Object.keys(filtroBusqueda).length > 0 ? true : false

        return <div style={{ marginBottom: 20 }} className="mt-0">
            <Row gutter={10}>
                <Col md={8} className="mb-3">
                    <Title className="mt-0" level={5}>Categoría</Title>
                    <SelectorGeneral key={key_input_filter} hideLabel={true} module="categorias" titulo="Categoría" condicion_default={{ tipo: "blog" }} onChange={(data) => agregarFiltro('tipo', data)} />
                </Col>
                {/* <Col md={4} className="mb-3">
                    <Title className="mt-0" level={5}>Sub categoría</Title>
                    <SelectorGeneral key={key_input_filter} hideLabel={true} mode="multiple" module="sub-categorias" titulo="Sub Categoría" condicion_default={{ tipo: "costos" }} onChange={(data) => agregarFiltro('subtipo', data)} />
                </Col> */}
                <Col md={8} className="mb-3">
                    <Title className="mb-2 mt-0" level={5}>FILTRAR</Title>
                    <Button loading={loadingConductores} onClick={() => refrescarBusqueda()} style={{ width: "100%" }} icon={<SearchOutlined />} >ACTUALIZAR</Button>
                </Col>
                <Col md={8} className="mb-3">
                    <Title className="mb-2 mt-0" level={5}>FILTRAR</Title>
                    <Button disabled={!mostrarButtonReset}  style={{ width: "100%" }} onClick={() => resetFilters()}>BORRAR FILTROS</Button>
                </Col>
            </Row>
        </div>
      }

      

      const render = <div>
        <Row gutter={15}>
            {buscador()}
            {/* <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
            <Col span="auto"><Button className="mb-3" disabled ><FileExcelFilled /> DESCARGAR PDF INFORMATIVO</Button></Col>
            <Col span="auto"><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green"  onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col> */}
        </Row>
        <Row gutter={15} className="mb-3">
        {mostrarDatosPersonalizados()}
        {mostrarGraficos()}
        </Row>
        {mostrarRegistros()}
      </div>

    return <div>
        {contextHolder}
        {
                showFilters === true ? <Row gutter={15}>
                <Col md={24}>
                    {mostrarFiltrosBusqueda()}
                </Col>
                <Col md={24}>{render}</Col>
            </Row> : <div>{render}</div>
        }
    </div>
}
export default ListadoRegistros