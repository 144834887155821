import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";
import { formatoMoneda } from "../../lib/helpers/main";
import { Col, Row, Skeleton, Tag, Tooltip } from "antd";
import { urlapi } from "../../lib/backend/data";

const EsquemaPrecios = (props) => {
    const {
        ids_origin,
        ids_destination
    } = props
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ datos, setDatos ] = useState([])
    const theme = createTheme({ palette: { mode: 'dark' } })

    const consultarDatos = async () => {
        if(ids_origin.length === 0 || ids_destination.length === 0) return setDatos([])
        setLoading(true)
        return fetch(`${urlapi}/disponibilidades/historia`,{
            method:'POST',
            body: JSON.stringify({
                ids_origin,
                ids_destination
            }),
            headers: {
                'Content-Type':'application/json',
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                setError("Sin datos")
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(Array.isArray(res.meses) !== false){
                setDatos(res.meses)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setError("Error al consultar")
        })
    }

    useEffect(() => {
        consultarDatos()
    }, [ ids_origin, ids_destination ])

    const mesesIndexados = {
        "01": "Ene",
        "02": "Feb",
        "03": "Mar",
        "04": "Abr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Ago",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dic",
    }

    if(loading) return <Skeleton />
    return <div>
        <h5>Valor promedio contratado en los últimos 6 meses</h5>
        
            <SparkLineChart
            data={datos.map(e => !isNaN(parseInt(e.promedio)) ? formatoMoneda(parseInt(e.promedio)) : 0)}
            xAxis={{
                scaleType: "band",
                data: datos.map(e => `${mesesIndexados[e.mesNum]} ${e.ano}`),
                // valueFormatter: (value) => formatoMoneda(value),
            }}
            height={80}
            showTooltip
            tooltip="bar"
            showHighlight
            />
                <Row gutter={25} className="mt-3" style={{ width: "100%" }}>
                    {
                        datos.map((e, i) => {
                            return <Col xs={24 / datos.length} key={i} style={{width:'100%'}}>
                                <p>{`${mesesIndexados[e.mesNum]}`}</p>
                                <p><b>{e.ano}</b></p>
                                <Tooltip title={ e.minimo ? `Valor promedio contratado en el mes entre ${formatoMoneda(e.minimo)} y ${formatoMoneda(e.maximo)}` : ""}>
                                <p className="mb-2">{e.minimo ? <Tag color="#02b2af" style={{ fontSize: 9 }} ><b>${formatoMoneda(e.minimo)} - ${formatoMoneda(e.maximo)}</b></Tag> : <Tag color="#d6d6d6" style={{ fontSize: 10 }} >Min. <b>Sin datos</b></Tag>}</p>
                                </Tooltip>
                            </Col>
                        })
                    }
                </Row>
    </div>
}

export default EsquemaPrecios;