import Title from "antd/es/typography/Title"
import ListadoRegistros from "../Blog/listado"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Card, message, Skeleton } from "antd"
import { useEffect, useState } from "react"
import { urlapi } from "../../lib/backend/data"
import Paragraph from "antd/es/typography/Paragraph"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6"
import { estilo_last_mile } from "../../lib/global/styles"

const BlogAyuda = () => {

        const dispatch = useDispatch()
        const [ loading, setLoading ] = useState(false)
        const [ idCategoria, setIdCategoria ] = useState(null)
        const [ condicion_busqueda, setCondicionBusqueda] = useState({ tipo: "blog"})
        const [ registros, setRegistros ] = useState([])
        const sesion                    = useSelector(state => state.miusuario)
        const [ messageApi, contextHolder] = message.useMessage();

        useEffect(() => {
            obtenerRegistros()
        },[])

        const obtenerRegistros = async (query)=>{
            setLoading(true)
            const condicion = query || condicion_busqueda
            return fetch(`${urlapi}/categorias/list/select`,{
                method:'POST',
                body: JSON.stringify({
                    condicion,
                }),
                headers: {
                    'Content-Type':'application/json',
                    'Authorization': `Bearer: ${sesion?.tokenSession}`
                }
            })
            .then(res => {
                if(res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                if(!res){
                    messageApi.error('Sin datos')
                } else if(res.errorMessage){
                    messageApi.error(res.errorMessage)
                } else if(Array.isArray(res) !== false){
                    setRegistros(res)
                }
                return setLoading(false)
            })
            .catch(error => {
                messageApi.error("Error al consultar la información, intente nuevamente")
                return setLoading(false)
            })
        }

        
        if(loading) return <div><Skeleton /></div>

        const render = () => {
            if(idCategoria) return <div>
                <Title level={2} className="mt-0 mb-2">{idCategoria?.title}</Title>
                <Button className="mb-3" onClick={() => setIdCategoria(null)} icon={<FaAngleLeft />}> VOLVER AL CENTRO DE AYUDA</Button>
                <ListadoRegistros condicion_default={{ id_categoria: idCategoria?._id }} subTitle={idCategoria?.title} />
            </div>
        
            return <div>
                {
                    registros.map((registro, index) => {
                        return <Card size="small" className="hover" key={registro._id} onClick={() => setIdCategoria(registro)} >
                            <Title level={4} className="mb-0 mt-0"><FaAngleRight style={estilo_last_mile.icon} /> {registro.title}</Title>
                            {/* <p>{registro.descripcion}</p> */}
                        </Card>
                    })
                }
            </div>
        }


    return <div>
        <Title level={4} className="mt-0 mb-0">Centro de Ayuda</Title>
        <Paragraph className="mb-2">Selecciona una categoría para ver los artículos de ayuda</Paragraph>
        {render()}
    </div>
}

export default BlogAyuda