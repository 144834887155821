export const statuses = [
    {
        value: "pending",
        label: "Pendiente",
        info: "El pago está pendiente de ser procesado, probablemente por falta de confirmación de la transacción."
    },
    {
        value: "confirmed",
        label: "Confirmado",
        info: "El pago está confirmado"
    },
]

export const stringByStatusPayment = (status) => {
    const indexar = statuses.reduce((acc, item) => {
        acc[item.value] = item.label
        return acc
    }, {})
    return indexar[status] ? indexar[status] : status
}

export const infoByStatusPayment = (status) => {
    const indexar = statuses.reduce((acc, item) => {
        acc[item.value] = item.info
        return acc
    }, {})
    return indexar[status] ? indexar[status] : status
}